/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useEffect, useState } from "react";
import PersonalizationHelper from "../tests/personalizationHelper";
import DashboardCard from "./dashboard-card";
import "./Dashboard.css";
import UserContext from "../../../../Store/user-context";
import NotificationContext from "../../../../Store/notification-store";
import Button from "../../../ds-gov/Button";
import DescriptionCardModal from "../../modals/description-modal";
import { useHistory, useLocation } from "react-router-dom";
import api_configuration from "../../../../api_configuration";
import api_obj from "../../../../api_configuration";
import axios from "axios";
import * as apiFunctions from "../../../../Store/apiFunctions";
import Card from "../../../ds-gov/Card";

function PersonalizedDashboardComponent() {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const notificationContext = useContext(NotificationContext);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  var dbh = new PersonalizationHelper();
  dbh.retrieve_indicators();
  let indicators = dbh.indicator_order;
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [regions, setRegions] = useState([]);
  const [mesoregions, setMesoregions] = useState([]);
  const [cities, setCities] = useState([]);
  const [uf, setUf] = useState([]);
  const [urlEstado, setUrlEstado] = useState("");
  const [urlMesoregion, setUrlMesoregion] = useState("");
  const [urlCities, setUrlCities] = useState("");

  useEffect(() => {
    apiFunctions.PostUnauth(
      api_obj.api_route.region_api,
      () => {},
      () => {},
      () => {},
      setRegions
    );
    if (urlMesoregion !== "")
      apiFunctions.PostUnauth(
        urlMesoregion,
        () => {},
        () => {},
        () => {},
        setMesoregions
      );
    if (urlCities !== "")
      apiFunctions.PostUnauth(
        urlCities,
        () => {},
        () => {},
        () => {},
        setCities
      );
    if (urlEstado !== "")
      apiFunctions.PostUnauth(
        urlEstado,
        () => {},
        () => {},
        () => {},
        setUf
      );
  }, [urlEstado, urlMesoregion, urlCities]);

  useEffect(() => {
    let tam = localStorage.getItem("countFont");
    let classElements = document.getElementsByTagName("*");
    for (let j = 0; j < tam - 1; j++) {
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        let styles = getComputedStyle(elem);
        elem.style.fontSize =
          parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) *
            1.05 +
          "px";
      }
      j += 1;
    }
    const currentZoom = Number(localStorage.getItem("zoom"));
    document.body.style.zoom = currentZoom;
    userContext.setBreadLocation([
      { name: "Dashboard", href: "/dashboard_selection", clickable: true },
      {
        name: "Dashboard Personalizado",
        href: "/personalized_dashboard",
        clickable: false,
      },
    ]);

    userContext.setAutocompleteToGlobal();

    // eslint-disable-next-line
  }, []);

  function getUserIndicators() {
    let groupIndicators = [];
    for (let i = 0; i < user.indicators_groups?.length; i++) {
      const group = user.indicators_groups[i];
      if (group.indicators) {
        for (let j = 0; j < group.indicators.length; j++) {
          const indicator = group.indicators[j];
          groupIndicators.push(indicator);
        }
      }
    }
    let unique = [...new Set(groupIndicators)];
    return unique;
  }

  function getFilterHash() {
    let indicatorsId = [];
    // lista de ids de indicadores
    for (let i = 0; i < indicators.length; i++) {
      const element = indicators[i];
      for (let j = 0; j < element.length; j++) {
        const ind = element[j];
        indicatorsId.push(ind);
      }
    }
    let filterHash = {};
    for (let i = 0; i < indicatorsId.length; i++) {
      filterHash = {
        ...filterHash,
        [indicatorsId[i]]: [],
      };
    }
    localStorage.setItem("savedPersonalizedHash", JSON.stringify(filterHash));
  }
  useEffect(() => {
    if (localStorage.getItem("savedPersonalizedHash") === null) getFilterHash();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line
  }, [localStorage.getItem("user")]);

  function createNewUserDashboard() {
    let token = JSON.parse(localStorage.getItem("token"))["token"];
    let createDashboardBody = {
      name: "Dashboard Personalizado",
      description: `Dashboard personalizado do usuário ${user["email"]}`,
    };
    fetch(api_configuration.api_route.new_dashboard_api, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createDashboardBody),
    }).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const createResponseData = isJson && (await response.json());
      if (response.ok) {
        let DbUserBody = {
          dashboard_id: createResponseData["id"],
          user_id: user["id"],
        };
        fetch(api_configuration.api_route.dashboard_user_api, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(DbUserBody),
        }).then(async (dbUserResponse) => {
          if (dbUserResponse.ok) {
            userContext.getUser(user.id);
            /*needed setTimeout to not setUser before update localstorage*/
            setTimeout(() => {
              setUser(JSON.parse(localStorage.getItem("user")));
            }, "300");
          } else {
            const error = dbUserResponse.statusText;
            return Promise.reject(error);
          }
        });
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    });
  }

  useEffect(() => {
    if (user["dashboards"].length === 1) createNewUserDashboard();

    notificationContext.showNotification({
      title: "Aviso",
      message:
        "Alguns indicadores podem demorar para carregar devido à grande quantidade de dados",
      status: "loading",
      position: "top-right",
    });
    // eslint-disable-next-line
  }, []);

  function gotoPersonalization() {
    localStorage.getItem("user") === null
      ? setOpenModal(true)
      : history.push(`/dashboard_personalization`);
  }

  return (
    localStorage.getItem("savedPersonalizedHash") && (
      <div className={`background_${userContext.contrast}`}>
        <div className="dashboard-personalization-button">
          <Button
            bground="secondary"
            psize="small"
            label="Personalizar dashboard"
            inverted={userContext.contrast === "contrast" && "inverted"}
            onclick={gotoPersonalization}
          />
        </div>
        <div className="main__page">
          <DescriptionCardModal
            open={openModal}
            onHandle={setOpenModal}
            cancelDescription="Fechar"
            description={
              "Você deve estar logado para personalizar o Dashboard."
            }
          />
          {indicators.length > 0 ? (
            indicators.flatMap((elem, index) => (
              <div key={"indicators_" + index} className="row">
                {elem.flatMap((elems, key) =>
                  elems !== null ? (
                    <div key={"items_" + key} className="row__inner">
                      <DashboardCard
                        id={elems}
                        availableIndicators={getUserIndicators()}
                        savedFilter={
                          location.state
                            ? location.state.savedFilter
                            : undefined
                        }
                        savedKeys={
                          location.state ? location.state.savedKeys : undefined
                        }
                        regions={regions.result}
                        mesoregions={mesoregions.result}
                        cities={cities.result}
                        uf={uf.result}
                        setUrlEstado={setUrlEstado}
                        setUrlMesoregion={setUrlMesoregion}
                        setUrlCities={setUrlCities}
                      />
                    </div>
                  ) : (
                    <div key={"items_" + key} className="row__invisible__div" />
                  )
                )}
              </div>
            ))
          ) : (
            <div style={{ margin: "50px" }}>
              {" "}
              <Card
                content={
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontSize: "20px" }}>
                      Desculpe, infelizmente não possuimos dados para exibir no
                      momento!{" "}
                    </p>
                  </div>
                }
              />{" "}
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default PersonalizedDashboardComponent;
