import React from "react";
import PNLD_logo from "../images/PNLD_logo.png";


function Logo() {
  return (
    <div>
        <img src={PNLD_logo} alt="PNLD_logo" />
    </div>
  );
}

export default Logo;
