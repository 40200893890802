/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useState, useEffect } from "react";
import "./management.css";
import Tab from "../../ds-gov/Tab";
import UserManagement from "./user";
import UserContext from "../../../Store/user-context";
import IndicatorsTable from "./indicatorsTable";
import * as apiFunctions from "../../../Store/apiFunctions";
import { CircularProgress } from "@mui/material";
import GroupsTable from "./groupsTable";
import FilterDimsTable from "./filters_dims_tables";
import api_configuration from "../../../api_configuration";
import DescriptionCardModal from "../modals/description-modal";
import Button from "../../ds-gov/Button";
import DashboardPersonalization from "../../../Pages/DashboardPersonalizatoin";
import List from "../../ds-gov/List";


function Gmanagement() {
  const userContext = useContext(UserContext);
  const [lastUpdateSync, setLastUpdateSync] = useState("");
  const [responseMessageModal, setResponseMessageModal] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [loadingDims, setLoadingDims] = useState(false);
  const [usersData, setUsersData] = useState();
  const [shouldUpdateData, setShouldUpdateData] = useState(false);
  const [groupsData, setGroupsData] = useState();
  const [filters, setFilters] = useState();
  const [dims, setDims] = useState();
  const [openSync, setOpenSync] = useState(false);
  const [indicatorsData, setIndicatorsData] = useState(false);
  const [shouldUpdateIndicators, setShouldUpdateIndicators] = useState(false);
  const [shouldUpdateFilters, setShouldUpdateFilters] = useState(false);
  const [shouldUpdateDims, setShouldUpdateDims] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const [tab, setTab] = useState(
    localStorage.getItem("tab") == undefined
      ? 0
      : parseInt(localStorage.getItem("tab"))
  );

  const [tab_content, setTabContent] = useState(
    user.role_id === 5
      ? [
        {
          aria_label: "usuários",
          label: "Usuários",
          sublabel: "Administração de usuários",
          active: tab === 0 ? "active" : "",
        },
        {
          aria_label: "Grupo de Indicadores",
          label: "Grupo de Indicadores",
          sublabel: "Grupo de Indicadores",
          active: tab === 1 ? "active" : "",
        },
        {
          aria_label: "Indicadores",
          label: "Indicadores",
          sublabel: "Administração de Indicadores",
          active: tab === 2 ? "active" : "",
        },
        {
          aria_label: "Filtros",
          label: "Filtros",
          sublabel: "Administração de Filtros",
          active: tab === 3 ? "active" : "",
        },
        {
          aria_label: "Dimensões",
          label: "Dimensões",
          sublabel: "Administração de Dimensões",
          active: tab === 4 ? "active" : "",
        },
        {
          aria_label: "Dashboard Público",
          label: "Dashboard Público",
          sublabel: "Administração do Dashboard Público",
          active: tab === 5 ? "active" : "",
        },
        {
          aria_label: "Sincronização",
          label: "Sincronização",
          sublabel: "Sincronização",
          active: tab === 6 ? "active" : "",
        },
      ]
      : [
        {
          aria_label: "usuários",
          label: "Usuários",
          sublabel: "Administração de usuários",
          active: tab === 0 ? "active" : "",
        },
        {
          aria_label: "Grupo de Indicadores",
          label: "Grupo de Indicadores",
          sublabel: "Grupo de Indicadores",
          active: tab === 1 ? "active" : "",
        },
        {
          aria_label: "Indicadores",
          label: "Indicadores",
          sublabel: "Administração de Indicadores",
          active: tab === 2 ? "active" : "",
        },
        {
          aria_label: "Filtros",
          label: "Filtros",
          sublabel: "Administração de Filtros",
          active: tab === 3 ? "active" : "",
        },
        {
          aria_label: "Dimensões",
          label: "Dimensões",
          sublabel: "Administração de Dimensões",
          active: tab === 4 ? "active" : "",
        },
      ]
  );

  function setOpenSyncModal() {
    setOpenSync(!openSync);
  }

  const TabhandleChange = (value) => {
    setTab(value);
    localStorage.setItem("tab", value);
  };

  useEffect(() => {
    let tam = localStorage.getItem('countFont');
    let classElements = document.getElementsByTagName("*");
    for (let j = 0; j < tam - 1; j++) {
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        let styles = getComputedStyle(elem);
        elem.style.fontSize = (parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) * 1.05 + 'px');
      }
      j += 1;
    }
    const currentZoom = Number(localStorage.getItem("zoom"));
    document.body.style.zoom = currentZoom;
    userContext.setBreadLocation([
      { name: "Administração", href: "/management", clickable: false },
    ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    apiFunctions.getUserTableData(setUsersData, setLoadingUser);
    apiFunctions.Get(
      api_configuration.api_route.get_groups_api,
      setGroupsData,
      undefined,
      setLoadingGroups
    );
    apiFunctions.Get(
      api_configuration.api_route.indicators_api,
      setIndicatorsData,
      undefined,
      setLoadingIndicators
    );
    apiFunctions.Get(
      api_configuration.api_route.filters_api,
      setFilters,
      undefined,
      setLoadingFilters
    );
    apiFunctions.Get(
      api_configuration.api_route.dims_api,
      setDims,
      undefined,
      setLoadingDims
    );
    apiFunctions.Get(
      api_configuration.api_route.last_update_api,
      setLastUpdateSync,
      undefined,
      undefined
    );

    userContext.getUser(user.id);
  }, []);

  useEffect(() => {
    apiFunctions.getUserTableData(setUsersData, setLoadingUser);
    apiFunctions.Get(
      api_configuration.api_route.get_groups_api,
      setGroupsData,
      undefined,
      setLoadingGroups
    );
    userContext.getPublicDashboard();
    userContext.getUser(user.id);

    // eslint-disable-next-line
  }, [shouldUpdateData]);

  useEffect(() => {
    apiFunctions.Get(
      api_configuration.api_route.indicators_api,
      setIndicatorsData,
      undefined,
      setLoadingIndicators
    );
    apiFunctions.Get(
      api_configuration.api_route.filters_api,
      setFilters,
      undefined,
      setLoadingFilters
    );
    apiFunctions.Get(
      api_configuration.api_route.dims_api,
      setDims,
      undefined,
      setLoadingDims
    );
  }, [shouldUpdateIndicators, shouldUpdateFilters, shouldUpdateDims]);

  useEffect(() => {
    setTabContent(
      user.role_id === 5
        ? [
          {
            aria_label: "usuários",
            label: "Usuários",
            sublabel: "Administração de usuários",
            active: tab === 0 ? "is-active" : "",
          },
          {
            aria_label: "Grupo de Indicadores",
            label: "Grupo de Indicadores",
            sublabel: "Grupo de Indicadores",
            active: tab === 1 ? "is-active" : "",
          },
          {
            aria_label: "Indicadores",
            label: "Indicadores",
            sublabel: "Administração de Indicadores",
            active: tab === 2 ? "is-active" : "",
          },
          {
            aria_label: "Filtros",
            label: "Filtros",
            sublabel: "Administração de Filtros",
            active: tab === 3 ? "is-active" : "",
          },
          {
            aria_label: "Dimensões",
            label: "Dimensões",
            sublabel: "Administração de Dimensões",
            active: tab === 4 ? "is-active" : "",
          },
          {
            aria_label: "Dashboard Público",
            label: "Dashboard Público",
            sublabel: "Administração do Dashboard Público",
            active: tab === 5 ? "is-active" : "",
          },
          {
            aria_label: "Sincronização",
            label: "Sincronização",
            sublabel: "Sincronização",
            active: tab === 6 ? "is-active" : "",
          },
        ]
        : [
          {
            aria_label: "usuários",
            label: "Usuários",
            sublabel: "Administração de usuários",
            active: tab === 0 ? "is-active" : "",
          },
          {
            aria_label: "Grupo de Indicadores",
            label: "Grupo de Indicadores",
            sublabel: "Grupo de Indicadores",
            active: tab === 1 ? "is-active" : "",
          },
          {
            aria_label: "Indicadores",
            label: "Indicadores",
            sublabel: "Administração de Indicadores",
            active: tab === 2 ? "is-active" : "",
          },
          {
            aria_label: "Filtros",
            label: "Filtros",
            sublabel: "Administração de Filtros",
            active: tab === 3 ? "is-active" : "",
          },
          {
            aria_label: "Dimensões",
            label: "Dimensões",
            sublabel: "Administração de Dimensões",
            active: tab === 4 ? "is-active" : "",
          },
        ]
    );
  }, [tab]);

  /* handle mui table contrast */
  useEffect(() => {
    let element;
    let style;

    if (userContext.contrast) {
      element = document.getElementsByClassName("MuiSvgIcon-root");
      for (let i = 0; i < element.length; i++) {
        element[i].style.color = "var(--color-secondary-01)";
      }
      element = document.getElementsByClassName("MuiTablePagination-root");
      for (let i = 0; i < element.length; i++) {
        element[i].style.color = "var(--color-secondary-01)";
      }

      /*change inline style*/
      style = document.createElement("style");
      style.innerHTML = `.MTablePaginationInner-root-14{
            color: white;
          }
          .contrast button:hover {
            background-color: #d1d1d1;
          }
          .MuiSelect-icon {
            color: white;
          }
          .MuiTableRow-root .MuiIcon-root{
            color: white;
          }

          .MuiTableRow-root .MuiSvgIcon-root{
            color: white;
          }
          .MuiTable-root  .MuiSvgIcon-root{
            color: white;
          }
          .MuiTable-root {
            background-color: #0a295c;
          }
          .MuiInputBase-root {
            color: var(--color-secondary-01);
          }

          .MuiTableBody-root{
            color: white;
          }


          /*table-nav header*/
          .container-management .br-tab .tab-nav{
            background-color: #0a295c;
          }
          .container-management .br-tab .tab-nav ul .tab-item button:hover .name {
            color: #0a295c;
          }
          .container-management .br-tab .tab-nav ul .tab-item button:not(:disabled):hover {
            background-color: #ffffff;
          }
          .br-tab .tab-nav ul .tab-item button:focus .name {
            color: #b0bec5;
          }
          .container-management .br-tab .tab-nav ul .tab-item button:focus .name {
            color: #b0bec5;
          }
          .br-tab .tab-nav ul .tab-item button:not(:disabled):hover .name {
            color: #0a295c;
          }
          .br-tab .tab-nav ul .tab-item.is-active button .name {
            color: #b0bec5;
          }
          .br-tab .tab-nav ul .tab-item button .name {
            color: white;
          }
          .br-tab .tab-nav ul .tab-item.is-active button:not(:disabled):hover .name {
            color: #263238;
          }
          .br-tab .tab-nav ul .tab-item.is-active button:not(:disabled):hover .name {
            color: #263238;
          }
          .MTableHeader-header-13{
            z-index: 0;
          }`;
      document.head.appendChild(style);
    } else {
      element = document.getElementsByClassName("MuiSvgIcon-root");
      for (let i = 0; i < element.length; i++) {
        element[i].style.color = "";
      }
      element = document.getElementsByClassName("MuiTablePagination-root");
      for (let i = 0; i < element.length; i++) {
        element[i].style.color = "";
      }

      /*change inline styling*/
      style = document.createElement("style");
      style.innerHTML = `.MTablePaginationInner-root-14{
            color: rgba(0, 0, 0, 0.54);
          }
          /*font color*/
          .MuiTableBody-root {
            color: black;
            font-weight: 400;
          }
          .MuiTable-root {
            background-color: white;
          }
          .MuiInputBase-root {
            color: rgba(0, 0, 0, 0.54);
          }
          .MuiTableCell-root{
            color: var(--color-secondary-09);
          }

          .MuiTable-root .MuiSvgIcon-root .MuiTablePagination-select {
            color: #1351b4;
            padding-top: 10px;
          }

          .MuiTableRow-root .MuiIcon-root {
            color: #1351b4;
          }

          .MuiIconButton-colorInherit{
            color: var(--color-primary-default);
          }
          
          .MTablePaginationInner-root-14 {
            color: #757575;
          }
          
          .MuiSelect-icon {
            color: #757575;
          }
          .MuiIcon-root{
            color: #1351b4;
          }

          /*table-nav header*/
          .container-management .br-tab .tab-nav{
            background-color: #f0f0f0;
          }
          .container-management .br-tab .tab-nav ul .tab-item button:hover .name {
            color: #0a295c;
          }
          .container-management .br-tab .tab-nav ul .tab-item button:not(:disabled):hover {
            background-color: #ffffff;
          }
          .container-management .br-tab .tab-nav ul .tab-item button:focus .name {
            color: #263238;
          }
          .br-tab .tab-nav ul .tab-item button:not(:disabled):hover .name {
            color: #0a295c;
          }
          .br-tab .tab-nav ul .tab-item.is-active button .name {
            color: #0a295c;
          }
          .br-tab .tab-nav ul .tab-item button .name {
            color: #333333;
          }
          .br-tab .tab-nav ul .tab-item.is-active button:not(:disabled):hover .name {
            color: #263238;
          }
          .br-tab .tab-nav ul .tab-item.is-active button:not(:disabled):hover .name {
            color: #0a295c;
          }
          .MuiTypography-h6 {
            font-size: 20px;
            font-weight: 500;
          }
          .MTableHeader-header-13{
            z-index: 0;
          }`;
      document.head.appendChild(style);
    }
  }, [userContext.contrast]);
  /**
   * TAB
   * 0 - Usuários
   * 1 - Grupo de Indicadores
   * 2 - Indicadores
   * 3 - Filtros
   * 4 - Dimensões
   * 5 - Sincronização
   */

  /* cores para a header nav*/

  const headerNavStyle = {
    backgroundColor: "#dadada",
    color: "#1c1c1c",
    fontSize: "16px",
    fontweight: "bold",
  };

  const [response, setResponse] = useState("")

  const [listEmail, setListEmail] = useState([])

  useEffect(() => {
    if (response.emailList) {
      let temp = response.emailList.map((email) => {
          return {
            primaryText: email,
            secondaryText: "",
            icon: "",
          };
        });
      let objectEmail = {
        label: "Email dos administradores",
        list: temp,
      };

      setListEmail([objectEmail]);
     
  
  }}, [response])

 

  let responseMessage = (
    <div>
      <p style={{ fontSize: "16px" }}>
        {response.message}
      </p>
      <div style={{marginTop: "20px"}}>
        <List expandable={true} contrast={`${userContext.contrast}`} content={listEmail} />
        <div className="br-accordion"></div>
      </div>
    </div>
  );


  function convertDate(dataString) {
    const meses = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];
    const diasSemana = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ];

    const data = new Date(dataString);
    const diaSemana = diasSemana[data.getDay()];
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const ano = data.getFullYear();

    const hora = data.getHours();
    const minuto = data.getMinutes();

    return `${diaSemana}, ${dia} de ${mes} de ${ano}, às ${hora} horas e ${minuto} minutos.`;
  }

  if (
    !loadingUser &&
    !loadingGroups &&
    !loadingIndicators &&
    !loadingFilters &&
    !loadingDims
  )
    return (
      <div className={`container-management ${userContext.contrast}`}>
        <div style={{ width: "80%" }}>
          <div className="invisible-div"></div>
          <Tab
            inverted={userContext.contrast && "inverted"}
            content={tab_content}
            handleChange={TabhandleChange}
          />
          <div className={"container__sections__column"}>
            {tab === 0 && usersData && (
              <UserManagement
                tstyle={headerNavStyle}
                data={usersData}
                roleId={user.role_id}
                shouldUpdateData={shouldUpdateData}
                setShouldUpdateData={setShouldUpdateData}
              />
            )}
            {tab === 1 && groupsData && usersData && (
              <GroupsTable
                tstyle={headerNavStyle}
                data={groupsData}
                shouldUpdateData={shouldUpdateData}
                setShouldUpdateData={setShouldUpdateData}
                users={usersData}
                indicatorsData={indicatorsData}
              />
            )}
            {tab === 2 && indicatorsData && (
              <IndicatorsTable
                tstyle={headerNavStyle}
                tabId={tab}
                filters={filters}
                dims={dims}
                roleId={user.role_id}
                data={indicatorsData}
                shouldUpdateData={shouldUpdateIndicators}
                setShouldUpdateData={setShouldUpdateIndicators}
              />
            )}
            {(tab === 3 || tab === 4) && (filters || dims) && (
              <FilterDimsTable
                tstyle={headerNavStyle}
                tabId={tab}
                roleId={user.role_id}
                filters={filters}
                dims={dims}
                shouldUpdateFilters={shouldUpdateFilters}
                shouldUpdateDims={shouldUpdateDims}
                setShouldUpdateFilters={setShouldUpdateFilters}
                setShouldUpdateDims={setShouldUpdateDims}
                users={usersData}
              />
            )}
            {tab === 5 && <DashboardPersonalization public={true} />}
            {tab === 6 && (
              <div className={`${userContext.contrast}-sync_content_div`}>
                <div className="sync_header">
                  <p>Sincronização</p>
                </div>
                <div className={`${userContext.contrast}sync_div`}>
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Button
                      bground="secondary"
                      psize="small"
                      label="Sincronizar Dados"
                      inverted={userContext.contrast === "contrast" && "inverted"}
                      onclick={() => {
                        apiFunctions.Get(api_configuration.api_route.sync_api, setResponse);
                        setResponseMessageModal(!responseMessageModal)
                      }}
                    />
                  </div>
                  <div>
                    <p style={{ fontSize: "15px", display: "flex", justifyContent: "flex-end" }}>
                      Última Sincronização: {convertDate(lastUpdateSync.lastUpdate)}
                    </p>
                  </div>
                </div>

                <DescriptionCardModal
                  open={responseMessageModal}
                  title={"Atenção"}
                  cancelDescription={"Fechar"}
                  icon={"none"}
                  onHandle={() => {
                    setResponseMessageModal(!responseMessageModal);
                  }}
                  description={responseMessage}
                />
              </div>
            )}
            {(loadingUser ||
              loadingIndicators ||
              loadingGroups ||
              loadingFilters ||
              loadingDims) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "15%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
          </div>
          <div className="invisible-div"></div>
        </div>
      </div>
    );
  else
    return (
      <div className={`container-management ${userContext.contrast}`}>
        {" "}
        <CircularProgress />
      </div>
    );
}

export default Gmanagement;
