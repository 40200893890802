import Input from "../Components/ds-gov/Input";
import Button from "../Components/ds-gov/Button";
import * as dfFunctions from "./dataframe";
import CustomSelect from "./CustomSelect";
import React, { useState, useEffect, memo, useContext } from "react";
import UserContext from "../Store/user-context";
import api_obj from "../api_configuration";

function BuildFilters(props) {
  let filters = props.filters;
  let setFilters = props.setFilters || function () {};
  let handleChangeKeys = props.handleChangeKeys;
  let handleChangeMesoregionsChecked = props.handleChangeMesoregionsChecked;
  let contrast = props.contrast;
  let filtersState = props.filtersState;
  let setAcLoading = props.setAcLoading;
  let loading = props.loading;
  let onHandle = props.onHandle || function () {};
  let saveLabel = props.label || "Filtrar";
  let saveIcon = props.icon || "fas fa-filter";
  let isLoading = props.isLoading || false;
  let enterFlag = props.enterFlag;
  let setEnterFlag = props.setEnterFlag || function () {};
  let newRegions = props.newRegions || [];
  let newMesoregions = props.newMesoregions || [];
  let newUf = props.newUf || [];
  let newCities = props.newCities || [];
  let setUrlUf = props.setUrlUf || function () {};
  let setUrlMesoregion = props.setUrlMesoregion || function () {};
  let setUrlCities = props.setUrlCities || function () {};
  let indicator = props.indicator || "";

  const userContext = useContext(UserContext);
  const [newFilters, setNewFilters] = useState(filters);
  const [unidadesFederativas, setUnidadeFederativa] = useState([]);
  const [mesoregions, setMesoregion] = useState([]);
  const [cities, setCities] = useState([]);
  const [regionsChecked, setRegionsChecked] = useState([]);
  const [ufChecked, setUfChecked] = useState([]);
  const [ufIdChecked, setUfIdChecked] = useState([]);
  const [mesoregionsChecked, setMesoregionsChecked] = useState([]);
  const [mesoregionsObjChecked, setMesoregionsObjChecked] = useState([]);
  const [regions, setRegions] = useState([]);
  let api_route = api_obj.api_route.indicator_api;

  const onCancelModal = () => {
    onHandle();
    userContext.toggleAccessibility();
  };

  useEffect(() => {
    let newURL;
    if (regionsChecked.length > 0) {
      newURL = api_route + "estado?dims=regiao_id&filters=regiao_id:[";
      for (let i = 0; i < regionsChecked.length; i++) {
        // eslint-disable-next-line
        newURL = newURL + `"${regionsChecked[i]}"` + ",";
      }
      newURL = newURL.substring(0, newURL.length - 1);
      newURL = newURL + "]";
    } else {
      newURL = api_route + "estado?dims=regiao_id";
    }
    setUrlUf(newURL);

    // eslint-disable-next-line
  }, [regionsChecked]);

  useEffect(() => {
    let newURL =
      api_route +
      "mesorregiao?dims=estado_id,estado_nome&filters=estado_nome:[";
    for (let i = 0; i < ufChecked.length; i++) {
      // eslint-disable-next-line
      newURL = newURL + `"${ufChecked[i]}"` + ",";
    }
    newURL = newURL.substring(0, newURL.length - 1);
    newURL = newURL + "]";
    setUrlMesoregion(newURL);
    // eslint-disable-next-line
  }, [ufChecked]);

  useEffect(() => {
    let newURL;
    if (mesoregionsChecked.length > 0) {
      newURL =
        api_route + "municipio?dims=mesorregiao_id&filters=mesorregiao_id:[";
      for (let i = 0; i < mesoregionsChecked.length; i++) {
        // eslint-disable-next-line
        newURL = newURL + `"${mesoregionsChecked[i]}"` + ",";
      }
      newURL = newURL.substring(0, newURL.length - 1);
      newURL = newURL + "]";
    } else {
      newURL = api_route + "municipio?dims=estado_id";
    }
    setUrlCities(newURL);
    // eslint-disable-next-line
  }, [mesoregionsChecked]);

  useEffect(() => {
    let newURL;
    if (ufIdChecked.length > 0) {
      newURL = api_route + "municipio?dims=estado_id&filters=estado_id:[";
      for (let i = 0; i < ufIdChecked.length; i++) {
        // eslint-disable-next-line
        newURL = newURL + `"${ufIdChecked[i]}"` + ",";
      }
      newURL = newURL.substring(0, newURL.length - 1);
      newURL = newURL + "]";
    } else {
      newURL = api_route + "municipio?dims=estado_id";
    }
    setUrlCities(newURL);
    // eslint-disable-next-line
  }, [ufIdChecked]);

  useEffect(() => {
    if (newRegions) {
      setRegions(
        newRegions.map((regiao) => {
          return {
            id: regiao.regiao_id,
            name: regiao.regiao_nome,
            checked: false,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  function handleChangeFilters(position, value) {
    let filtersArray = [...newFilters];
    filtersArray[position].value = value;
    setNewFilters(filtersArray);
  }

  const onSaveModal = () => {
    setFilters(newFilters);
    handleChangeMesoregionsChecked(mesoregionsObjChecked);
    handleChangeKeys(dfFunctions.getKeys());
    onHandle();
  };

  const filterHash = JSON.parse(sessionStorage.getItem("filterHash"));

  const filterNameHash = {
    "Por Editora": { route: "ac_publisher", key: "publisher_name" },
    "Por Escola": { route: "ac_school_name", key: "school_name" },
    "Por Código da Escola": { route: "ac_school_code", key: "school_code" },
    "Por Disciplina": {
      route: "ac_book_subject_code",
      key: "book_subject_code",
    },
    "Por Nome": { route: "ac_book_name", key: "book_name" },
    "Por Código do Programa do FNDE": {
      route: "ac_program_code",
      key: "program_code",
    },
    "Por DS do Programa do FNDE": {
      route: "ac_program_descr",
      key: "program_descr",
    },
    "Por Nome do Programa do FNDE": {
      route: "ac_program_name",
      key: "program_name",
    },
  };

  let cancelButton = (
    <Button
      bground="secondary"
      pstate=""
      psize="small"
      label="Cancelar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      onclick={onCancelModal}
    />
  );

  let okButton = (
    <Button
      bground="secondary"
      pstate={isLoading ? "disabled" : ""}
      onclick={onSaveModal}
      psize="small"
      icon={saveIcon}
      inverted={userContext.contrast === "contrast" && "inverted"}
      label={saveLabel}
    />
  );

  let footer = (
    <>
      {cancelButton}
      {okButton}
    </>
  );

  useEffect(() => {
    let filterHash = {};
    for (let i = 0; i < filters.length; i++) {
      filterHash = {
        ...filterHash,
        [filters[i].filter_name]: filters[i].value,
      };
    }
    sessionStorage.setItem("filterHash", JSON.stringify(filterHash));
    // eslint-disable-next-line
  }, []);

  const handleChangeRegions = (filterStateIndex, checkedArray) => {
    setRegionsChecked(checkedArray);
    setUnidadeFederativa([]);
    handleChangeFilters(filterStateIndex, checkedArray);
  };

  useEffect(() => {
    if (newUf.length > 0) {
      setUnidadeFederativa(
        newUf.map((uf) => {
          return {
            id: uf.estado_id,
            name: uf.estado_nome,
            checked: false,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [newRegions]);

  useEffect(() => {
    if (regionsChecked.length > 0)
      setUnidadeFederativa(
        newUf.map((uf) => {
          return {
            id: uf.estado_id,
            name: uf.estado_nome,
            checked: false,
          };
        })
      );
  }, [newUf]);

  useEffect(() => {
    if (newCities.length > 0) {
      setCities(
        newCities.map((city) => {
          return {
            id: city.municipio_id,
            name: city.municipio_nome,
            checked: false,
          };
        })
      );
    }
    setMesoregion(
      newMesoregions.map((mesoregion) => {
        return {
          id: mesoregion.mesorregiao_id,
          name: mesoregion.mesorregiao_nome,
          uf: mesoregion.estado_nome,
          checked: false,
        };
      })
    );
    // eslint-disable-next-line
  }, [unidadesFederativas]);

  const handleChangeUf = (filterStateIndex, checkedArray) => {
    setUfIdChecked(checkedArray);
    let checkedufs = [];
    // need the nested loop in order to get the ufs name to use in the filter url
    for (let i = 0; i < unidadesFederativas.length; i++) {
      const element = unidadesFederativas[i];
      for (let j = 0; j < checkedArray.length; j++) {
        const index = checkedArray[j];
        if (element.id === index) {
          checkedufs.push(element.name);
          break;
        }
      }
    }
    setMesoregion([]);
    setUfChecked(checkedufs);
    handleChangeFilters(filterStateIndex, checkedufs);
  };

  useEffect(() => {
    if (newCities.length > 0)
      setCities(
        newCities.map((city) => {
          return {
            id: city.municipio_id,
            name: city.municipio_nome,
            checked: false,
          };
        })
      );
    // eslint-disable-next-line
  }, [mesoregions]);

  const handleChangeMesoregion = (filterStateIndex, checkedArray) => {
    let newMesoregionsCheckedObject = [];
    for (let i = 0; i < mesoregions.length; i++) {
      const element = mesoregions[i];
      for (let j = 0; j < checkedArray.length; j++) {
        const index = checkedArray[j];
        if (element.id === index) {
          newMesoregionsCheckedObject.push({
            id: element["id"],
            name: element["name"],
            uf: element["uf"],
          });
          break;
        }
      }
    }
    setCities([]);
    setMesoregionsObjChecked(newMesoregionsCheckedObject);
    setMesoregionsChecked(checkedArray);
    handleChangeFilters(filterStateIndex, checkedArray);
  };

  useEffect(() => {
    setCities(
      dfFunctions.getCity(mesoregionsChecked).map((city) => {
        return {
          id: city[0],
          name: city[1],
          checked: false,
        };
      })
    );
  }, [mesoregionsChecked]);

  const handleChangeCities = (filterStateIndex, checkedArray) => {
    let newCitiesChecked = [];
    // need the nested loop in order to get the ufs name to use in the filter url
    for (let i = 0; i < cities.length; i++) {
      const element = cities[i];
      for (let j = 0; j < checkedArray.length; j++) {
        const index = checkedArray[j];
        if (element.id === index) {
          newCitiesChecked.push(element.name);
          break;
        }
      }
    }
    handleChangeFilters(filterStateIndex, newCitiesChecked);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onSaveModal();
        setEnterFlag(!enterFlag);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        onCancelModal();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  function searchArrayOfHashes(array, key, value) {
    // Filter the array to find hashes that match the key-value pair
    return createUnique(
      array.filter((hash) => hash[key] === value),
      "col"
    );
  }

  function createUnique(array, key) {
    const uniqueValuesSet = new Set(array.map((item) => item[key]));
    return Array.from(uniqueValuesSet);
  }



  function getAcKey(){
    let novoAcKey =
      userContext.getAutocompleteToGlobal() !== undefined
        ? searchArrayOfHashes(
            JSON.parse(userContext.getAutocompleteToGlobal()),
            "route",
            indicator
          )
        : "";

    return novoAcKey;
  }

  return (
    <div>
      {React.Children.toArray(
        filters.map((filter, index) => {
          if (
            // Para não quebrar a aplicação, só adicionamos os !!! no fim da string. Para refatorar temos que tirar esse if
            filter.filter_api_callable !== "region!!!" &&
            filter.filter_api_callable !== "city!!!" &&
            filter.filter_api_callable !== "uf!!!" &&
            filter.filter_api_callable !== "mesoregion!!!"
          ) {
            let acRoute = filterNameHash[filter.filter_name]
              ? filterNameHash[filter.filter_name].route
              : "";
   
            let acKey = getAcKey().includes(filter.filter_api_callable) ? filter.filter_api_callable : ""

            return (
              <div>
                <Input
                  isAutocomplete={true}
                  brInputClassName={`br-input input-${contrast}`}
                  labelText={filter["filter_name"]}
                  inputType="text"
                  inputPlaceholder="Digite o valor"
                  inputValue={filtersState[index].value}
                  handleChange={handleChangeFilters}
                  index={index}
                  labelFor={filter["filter_api_callable"]}
                  inputId={filter["filter_api_callable"]}
                  autocompleteRoute={acRoute}
                  autocompleteKey={acKey}
                  setAcLoading={setAcLoading}
                  indicator={indicator}
                />
                <div style={{ height: "1rem" }} />
              </div>
            );
          } else {
            if (
              filter.filter_api_callable === "region" &&
              regions.length > 0 &&
              !loading
            )
              return (
                <div>
                  <CustomSelect
                    options={regions}
                    defaultValue={filterHash[filter.filter_name]}
                    label={filter.filter_name}
                    placeholder="Selecione as Regiões"
                    filterName={filter.filter_name}
                    handleChange={(checkedArray) => {
                      handleChangeRegions(index, checkedArray);
                    }}
                  />
                  <div style={{ height: "1rem" }} />
                </div>
              );
            else if (
              filter.filter_api_callable === "uf" &&
              regionsChecked.length > 0 &&
              unidadesFederativas.length > 0
            )
              return (
                <div>
                  <CustomSelect
                    options={unidadesFederativas}
                    defaultValue={filterHash[filter.filter_name]}
                    label={filter.filter_name}
                    placeholder="Selecione as Unidades Federativas"
                    filterName={filter.filter_name}
                    handleChange={(checkedArray) => {
                      handleChangeUf(index, checkedArray);
                    }}
                  />
                  <div style={{ height: "1rem" }} />
                </div>
              );
            else if (
              filter.filter_api_callable === "mesoregion" &&
              ufChecked.length > 0 &&
              mesoregions.length > 0
            )
              return (
                <div>
                  <CustomSelect
                    options={mesoregions}
                    defaultValue={filterHash[filter.filter_name]}
                    label={filter.filter_name}
                    placeholder="Selecione as Mesorregiões"
                    filterName={filter.filter_name}
                    handleChange={(checkedArray) => {
                      handleChangeMesoregion(index, checkedArray);
                    }}
                  />
                  <div style={{ height: "1rem" }} />
                </div>
              );
            else if (filter.filter_api_callable === "city" && cities.length > 0)
              return (
                <div>
                  <CustomSelect
                    options={cities}
                    defaultValue={filterHash[filter.filter_name]}
                    label={filter.filter_name}
                    placeholder="Selecione as Cidades"
                    filterName={filter.filter_name}
                    handleChange={(checkedArray) => {
                      handleChangeCities(index, checkedArray);
                    }}
                  />
                  <div style={{ height: "1rem" }} />
                </div>
              );
          }
          return <></>;
        })
      )}
      <div className={`br-modal-footer justify-content-center`}>{footer}</div>
    </div>
  );
}

export default memo(BuildFilters);
