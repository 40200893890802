import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { TableHead } from "@mui/material";

import UserContext from "../../../Store/user-context";
import { useState, useContext, useEffect } from "react";

import translation from "./dictionary_reporsts.json";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Reports({ data, definePage, pageState }) {
  // eslint-disable-next-line
  const [keys, setKeys] = useState(getKeys());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [originalData, setOriginalData] = useState(data);
  useEffect(() => {
    setOriginalData(data);
  }, [data]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, keys.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const userContext = useContext(UserContext);
  function getKeys() {
    let objectKeys = [];
    if (data.length > 0) {
      objectKeys = Object.keys(data[0]);
    }
    return objectKeys;
  }
  return (
    <div
      className={"reports_container"}
      style={{
        minWidth: "98%",
        maxWidth: "98%",
        position: "relative",
        alignItems: "center",
      }}
    >
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          sx={{
            maxWidth: "100%",
            backgroundColor: userContext.contrast ? "#071d41" : "white",
          }}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {/* <TableCell sx={{ backgroundColor: userContext.contrast ? "#0c326f" : (224, 224, 224) }}>{keys[0]}</TableCell> */}
              {keys.map((column, index) => (
                // index > 0 &&
                <>
                  <TableCell
                    sx={{
                      backgroundColor: userContext.contrast
                        ? "#0c326f"
                        : (224, 224, 224),
                      color: userContext.contrast ? "white" : "black",
                    }}
                  >
                    {translation[column] !== undefined
                      ? translation[column]
                      : column}
                    {/* {column} */}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((filters, index) => (
              <>
                <TableRow
                  key={filters[keys[0]]}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell sx={{ color: userContext.contrast ? "white" : "black" }} >{filters[keys[0]]}</TableCell> */}
                  {keys.map((column, i) => (
                    // i > 0 &&
                    <>
                      <TableCell
                        align="right"
                        scope="row"
                        sx={{ color: userContext.contrast ? "white" : "black" }}
                      >
                        {filters[column]}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </>
            ))}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      <div className="status-report">
        <button
          className="br-button primary large m-2"
          style={{ float: "left" }}
          onClick={() => {
            definePage(pageState - 1);
          }}
        >
          Voltar
        </button>
        <span style={{ fontSize: 16 }}>Página {pageState}</span>
        <button
          className="br-button primary large m-2"
          style={{ float: "right" }}
          onClick={() => {
            definePage(pageState + 1);
          }}
        >
          Avançar
        </button>
      </div>
    </div>
  );
}
