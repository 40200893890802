// import LineChartComponent from "../Components/app/charts/line-chart";
import BarChartComponent from "../Components/app/charts/bar-chart";
import LineChartComponent from "../Components/app/charts/line-chart";
import BasicTable from "../Components/app/charts/table";
import List from "../Components/ds-gov/List";
import Reports from "../Components/app/charts/reports";

export default function PlotGraph(params) {

  const {
    chartType,
    isStackedBar,
    data,
    contrast,
    fullPage,
    label,
    presentationShape,
    transformed, 
    qte,
    definePage,
    page
  } = params;


  if (presentationShape === "list" ) {
    return (
      <List
        title={label}
        expandable={true}
        contrast={contrast}
        content={transformed}
        fullPage={fullPage}
        divide={true}
      />
    );
  }
  else if (Number(chartType) === 3 || qte > 40) {
    return (
      <BasicTable data={transformed} />
    );
  }
  else if (Number(chartType) === 5) {
    return (
      <Reports data={transformed} definePage={definePage} pageState={page}/>
    );
  }
  else if (Number(chartType) === 1) {
    return (
      <LineChartComponent
        contrast={contrast}
        data={transformed}
        keys={data}
        fullPage={fullPage}
      />
    );
  } else if (Number(chartType) === 2) {
    return (
      <BarChartComponent
        contrast={contrast}
        data={transformed}
        isStackedBar={false}
        fullPage={fullPage}
        keys={data}
        label={label}
      />
    );
  } else if (Number(chartType) === 4) {
    return (
      <BarChartComponent
        contrast={contrast}
        data={transformed}
        isStackedBar={isStackedBar}
        fullPage={fullPage}
        keys={data}
        label={label}
      />
    );
  }

}
