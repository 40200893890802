/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext, useEffect } from "react";
import Header from "./header";
import AlphaBar from "./alpha-bar";
import Footer from "./footer";
import Toast from "../ui/Toast";
import NotificationContext from "../../../Store/notification-store";
import "./layout.css";
import UserContext from "../../../Store/user-context";
import Button from "../../ds-gov/Button";
import Bread from "./breadcrumb";
/* Parameters
 * handleLogin: function
 * handleContrast: function
 * contrast: String */

function Layout(props) {
  const notificationContext = useContext(NotificationContext);
  const userContext = useContext(UserContext);
  const activeNotification = notificationContext.notification;
  let handleLogin = props.handleLogin || function () {};
  let loginConfirm = props.loginConfirm || function () {};
  let handleAccessibility = props.handleAccessibility || function () {};
  let handleContrast = props.handleContrast || function () {};
  let contrast = props.contrast || "";
  let children = props.children;

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  let keysPressed = {};
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (
        localStorage.getItem("accessibility") === "true" &&
        (event.key === "Alt" ||
          event.key === "1" ||
          event.key === "2" ||
          event.key === "3")
      ) {
        keysPressed[event.key] = true;
        if (keysPressed["Alt"] && keysPressed["1"]) topFunction();
        if (keysPressed["Alt"] && keysPressed["2"]) {
          document.getElementById("children_class").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
        if (keysPressed["Alt"] && keysPressed["3"])
          document.getElementById("footer___div").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        event.preventDefault();
      }
    };
    const keyUpHandler = (event) => {
      delete keysPressed[event.key];
    };
    document.addEventListener("keydown", keyDownHandler);
    document.addEventListener("keyup", keyUpHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      document.removeEventListener("keyup", keyUpHandler);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main___container">
      <Header
        handleLogin={() => {
          userContext.closeAccessibility();
          handleLogin();
        }}
        loginConfirm={() => {
          userContext.toggleAccessibility();
          loginConfirm();
        }}
        handleContrast={handleContrast}
        handleAccessibility={handleAccessibility}
        contrast={contrast}
      />
      <div
        className={`children_class children_${userContext.contrast}`}
        id={"children_class"}
      >
        <div>
          <Bread />
        </div>
        {children}
        <div className="myBtn">
          <Button
            bground="secondary"
            pstate=""
            onclick={topFunction}
            psize="large"
            circle={"circle"}
            icon="fas fa-arrow-up fa-2x"
            inverted={userContext.contrast === "contrast" ? "" : "inverted"}
          />
        </div>
      </div>
      <div id={"footer___div"}>
        <Footer />
      </div>
      {activeNotification && (
        <Toast
          title={activeNotification.title}
          message={activeNotification.message}
          status={activeNotification.status}
          position={activeNotification.position}
        />
      )}
    </div>
  );
}

export default Layout;
