/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import "./dashboard-card.css";
import Button from "../../../ds-gov/Button";
import UserContext from "../../../../Store/user-context";
import NotificationContext from "../../../../Store/notification-store";
import DescriptionModal from "../../modals/description-modal";
import FilterModal from "../../modals/filter-modal";
import axios from "axios";
import PlotGraph from "../../../../helpers/dashboard";
import BuildFilters from "../../../../helpers/dashboard-filter";
import api_configuration from "../../../../api_configuration";
import lodash from "lodash";
import { CircularProgress, Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import indicator_config from "../indicator_config.json"


/* Params
 * id: number */

function DashboardCard(props) {
  let { id, fullPage, availableIndicators, savedFilter, savedKeys, regions, mesoregions, uf, cities, setUrlEstado, setUrlMesoregion, setUrlCities } = props;
  let indicator = getIndicatorById(id);
  const history = useHistory();
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);
  const [keys, setKeys] = useState(savedKeys ? savedKeys : undefined);
  const [mesoregionsId, setMesoregionsId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acLoading, setAcLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [isBeingResized, setIsBeingResized] = useState(false);
  const [chartType, setChartType] = useState(getDefaultValue(indicator["indicator_presentation_shape"]));
  const [filterFlag, setFilterFlag] = useState(false);
  let api_route = api_configuration.api_route.indicator_api;
  let urlBase = undefined
  const [API_URL, setAPI] = useState(urlBase);
  const [downloadUrl, setDownloadUrl] = useState(urlBase + "?format=csv");
  const [enterFlag, setEnterFlag] = useState(false);
  const [urlBody, setUrlBody] = useState({});
  const [download, setDownload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [transformed, setTransformed] = useState([])
  const [qte, setQte] = useState(1)
  const [page, setPage] = useState(1);
  const [downloadAutocomplete, setAutocomplete] = useState(false)

  useEffect(() => {
    if (!indicator["filters"]) {
      indicator["filters"] = [];
    }
    setFilters(savedFilter && savedFilter[id].length > 0
      ? savedFilter[id]
      : indicator["filters"].map((filter) => {
        return {
          ...filter,
          value: "",
        };
      }),);

  }, []);

  useEffect(() => {
    if (!downloadAutocomplete) {
      userContext.setAutocompleteToGlobal();
      setAutocomplete(true)
    }
  }, [])


  function myFunctionSelect() {
    document.getElementById("myDropdownSelect" + id).classList.toggle("showSelect");
  }

  function translateGraphicType(shape) {
    switch (shape) {
      case 1:
        return "Linha";
      case 2:
        return "Coluna";
      case 3:
        return "Tabela";
      case 4:
        return "Coluna Agrupada";
      case 5:
        return "Relatório";
    }
  }

  useEffect(() => {
    if (download === true) {
      axios({
        url: downloadUrl, //your url
        method: 'POST',
        data: JSON.stringify(urlBody),
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'file.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }
  }, [download])

  function getIndicatorById(id) {
    if (!availableIndicators) return []
    for (let i = 0; i < availableIndicators.length; i++) {
      const indicator = availableIndicators[i];
      if (indicator.id === id) return indicator;
    }
  }

  // eslint-disable-next-line
  const reset = useCallback(
    lodash.debounce(() => {
      setIsBeingResized(false);
    }, 200),
    []
  );

  useEffect(() => {
    const handleResize = () => {
      setIsBeingResized(true);
      reset();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [setIsBeingResized]);

  useEffect(() => {
    setLoading(true);
    if (indicator["indicator_presentation_shape"] === "reports") {
      urlBase = api_route + indicator["indicator_route"] + "?page=" + page;
    } else {
      urlBase = api_route + indicator["indicator_route"];
    }
    async function fetchData() {
      axios
        .post(urlBase, JSON.stringify(urlBody))
        .then((response) => {
          if (response.statusText === "OK") return response.data.result;
          else throw new Error("Error");
        })
        .then((data) => {
          setData(data);
          if (indicator["indicator_presentation_shape"] === "list")
            setTransformed(treatListDataIndicator(data));
          else {
            if (indicator["indicator_presentation_shape"] === "reports")
              setTransformed(data);
            else
              setTransformed(
                treatData(data, keys, mesoregionsId, indicator["label"])
              );
          }
        })
        .catch((error) => {
          setError(true);
          notificationContext.showNotification({
            title: "Erro",
            message: "Não foi possível carregar os dados",
            status: "error",
            position: "top-right",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line
  }, [urlBody, page]);

  useEffect(() => {
    buildURL();

    // eslint-disable-next-line
  }, [enterFlag]);

  function buildURL() {
    let filterKey = "";
    let dims = "";
    let newURL = "";
    let url_dims = "";
    let url_filters = "";
    let urlBodyObject = { filters: {} };
    for (let index = 0; index < filters.length; index++) {
      let filter = filters[index];
      if (filter.value !== "" && filter.value.length > 0) {
        filterKey += `${filter.filter_api_callable}:[`;
        if (filter.filter_api_callable === "mesoregion") {
          for (let i = 0; i < filter.value.length; i++) {
            const item = filter.value[i];
            let codigoString = item.toString();
            codigoString = codigoString.substring(2, 4);
            filterKey += `"${codigoString}",`;
          }
        } else {
          if (typeof filter.value === "string") {
            filter.value = filter.value.replace(/'/g, "''"); // Escape single quotes
          } else {
            for (let i = 0; i < filter.value.length; i++) {
              let item = filter.value[i];
              if (typeof item === "string") {
                filter.value[i] = item.replace(/'/g, "''"); // Escape single quotes
              }
            }
          }
          filter.value =
            typeof filter.value === "string" ? [filter.value] : filter.value;
        }
        urlBodyObject.filters[`${filter.filter_api_callable}`] = filter.value;
        filterKey = filterKey.substring(0, filterKey.length - 1);
        filterKey += `],`;
        dims += `${filter.filter_api_callable},`;
      }
    }

    url_dims = dims.substring(0, dims.length - 1);
    url_filters = filterKey.substring(0, filterKey.length - 1);
    newURL = urlBase + "?dims=" + url_dims + "&filters=" + url_filters;
    setAPI(newURL);
    if (!url_dims && !url_filters) setUrlBody({});
    else setUrlBody(urlBodyObject);
    return newURL;
  }

  function clearFilters() {
    const newFilters = [...filters];
    for (let index = 0; index < newFilters.length; index++) {
      newFilters[index].value = "";
    }
    setFilters(newFilters);
    setAPI(urlBase);
    if (error) setError(false);

  }

  function handleChangeMesoregions(newMesoregionsChecked) {
    setMesoregionsId(newMesoregionsChecked);
  }

  function handleChangeKeys(keys) {
    setKeys(keys);
  }

  function handleDescriptionModal() {
    setOpenModal((prevState) => !prevState);
  }

  function handleFilterModal() {
    setOpenFilter((prevState) => !prevState);
  }

  useEffect(() => {
    buildURL();
    let newFilterHash = JSON.parse(
      localStorage.getItem("savedPersonalizedHash")
    );
    newFilterHash[id] = filters;
    localStorage.setItem(
      "savedPersonalizedHash",
      JSON.stringify(newFilterHash)
    );
    setFilterFlag(false);
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.value.length > 0) setFilterFlag(true);
    }
    // eslint-disable-next-line
  }, [filters]);

  function redirectTo(path) {
    history.push({
      pathname: path,
      state: {
        id: id,
        availableIndicators: availableIndicators,
        savedFilter: JSON.parse(localStorage.getItem("savedPersonalizedHash")),
        savedKeys: keys,
      },
    });
  }

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape" && fullPage) {
        event.preventDefault();
        redirectTo("/dashboard");
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);

  function myFunction() {
    document.getElementById("myDropdown" + id).classList.toggle("show");
  }

  // Close the dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    if (!event.target.matches(`.dropbtn_${userContext.contrast}`)) {
      let dropdowns = document.getElementsByClassName(`dropdown_content_${userContext.contrast}`);
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
    if (!event.target.matches(`.selectDropbtnPers_`)) {
      let selectDropdowns = document.getElementsByClassName(
        `selectDropdown_content_`
      );
      let i;
      for (i = 0; i < selectDropdowns.length; i++) {
        let openDropdownSelect = selectDropdowns[i];
        if (openDropdownSelect.classList.contains("showSelect")) {
          openDropdownSelect.classList.remove("showSelect");
        }
      }
    }
  };

  function convertDate(dataString) {
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    const diasSemana = [
      'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'
    ];

    const data = new Date(dataString);
    const diaSemana = diasSemana[data.getDay()];
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const ano = data.getFullYear();

    return `${diaSemana}, ${dia} de ${mes} de ${ano}`;
  }


  let filterSpan = (
    <>
      <div style={{ top: "-3px", position: "relative" }}>
        <div className="span_filter">Filtro: </div>
        {filterFlag ? (
          <div className="activated_filter">
            Ativado
          </div>
        ) : (
          <div className="disable_filter">
            Desativado
          </div>
        )}
      </div>
    </>
  );

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape" && fullPage) {
        event.preventDefault();
        redirectTo("/personalized_dashboard");
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line
  }, []);



  // talvez mude se decidirmos que os tipos de grafico serao dinamicos
  function getDefaultValue(shape) {
    switch (shape) {
      case "line":
        return 1;
      case "bar":
        return 2;
      case "table":
        return 3;
      case "stackedbar":
        return 4;
      case "reports":
        return 5;
        break;
      default:
        return 2;
    }
  }

  const [stackedBar, setStackedBar] = useState(false);


  useEffect(() => {
    switch (indicator["indicator_presentation_shape"]) {
      case "line":
        setChartType(1);
        break;
      case "bar":
        setChartType(2);
        break;
      case "table":
        setChartType(3);
        break;
      case "stackedbar":
        setStackedBar(true);
        setChartType(4);
        break;
      case "reports":
        setChartType(5);
        break;
      default:
        setChartType(2);
        break;
    }
    // eslint-disable-next-line
  }, [indicator["indicator_presentation_shape"]]);

  function treatString(string) {
    let newString = "";
    let count = 0;
    for (let i = string.length - 1; i >= 0; i--) {
      const char = string[i];
      newString = char + newString;
      if (i > 0 && count > 0 && count % 3 === 0) {
        newString = "." + newString;
      }
      if (i <= string.length - 3) {
        count++;
      }
    }
    return newString;
  }

  function treatListDataIndicator(data) {
    const dataKeyArray = [];
    let dataKeyArrayUnique = [];
    let finalArray = [];
    const dtKey = indicator["base_column"]
    data.forEach((element) => {
      dataKeyArray.push(element[dtKey].toString());
    });
    dataKeyArrayUnique = [...new Set(dataKeyArray)];
    dataKeyArrayUnique.forEach((uniqueYear) => {
      finalArray.push({
        label: uniqueYear,
        total: 0,
        list: [],
      });
    });

    finalArray.forEach((finalElement) => {
      data.forEach((dataElement, i) => {
        if (finalElement["label"] === dataElement[dtKey].toString()) {
          finalElement.total = finalElement.total + 1;
          finalElement.list.push({
            primaryText:
              dataElement["total"] !== undefined
                ? `${dataElement["name"]} - R$ ${treatString(
                  Number(dataElement["total"]).toFixed(2).replace(".", ",")
                )}`
                : dataElement["name"],
            secondaryText: "",
            icon: "fas fa-building",
          });
        }
      });
    });
    /*return sorted array by period */
    return finalArray.sort((a, b) => {
      return a.label - b.label;
    });
  }


  function treatData(data, keys, mesoregionsId, renamedDefaultKey) {
    const dataKeyArray = [];
    let dataKeyArrayUnique = [];
    let finalArray = [];
    const dtKey = indicator["base_column"]
    data.forEach((element) => {
      dataKeyArray.push(element[dtKey].toString());
    });
    dataKeyArrayUnique = [...new Set(dataKeyArray)];
    dataKeyArrayUnique.forEach((uniqueYear) => {
      let finalArrayObject = {
        period: uniqueYear,
      };
      data.forEach((element) => {
        if (element[dtKey].toString() === uniqueYear.toString()) {
          if (element.hasOwnProperty("city")) {
            finalArrayObject[`${element["city"]}`] = Number(element["total"]);
          } else if (element.hasOwnProperty("mesoregion")) {
            for (let i = 0; i < mesoregionsId.length; i++) {
              keys[1].forEach((key) => {
                if (
                  Number(mesoregionsId[i]["id"]) === Number(key["id"]) &&
                  mesoregionsId[i]["uf"] === element["uf"] &&
                  Number(mesoregionsId[i]["id"].toString().substring(2, 4)) ===
                  Number(element["mesoregion"])
                ) {
                  finalArrayObject[`${key["name"]}`] = Number(element["total"]);
                }
              });
            }
          } else if (element.hasOwnProperty("uf")) {
            finalArrayObject[`${element["uf"]}`] = Number(element["total"]);
          } else if (element.hasOwnProperty("region")) {
            keys[0].forEach((key) => {
              if (Number(element["region"]) === key["id"]) {
                finalArrayObject[`${key["name"]}`] = Number(element["total"]);
              }
            });
          } else if (element.hasOwnProperty("stage")) {
            if (typeof element["grade_code"] === "number") finalArrayObject[`${element["stage"]}`] = Number(element["total"]);
          }
          else {
            finalArrayObject[renamedDefaultKey] = Number(element["total"]);
          }
        }
      });
      finalArray.push(finalArrayObject);
    });
    /*return sorted array by period */
    if (finalArray[0] !== undefined && Object.keys(finalArray[0]).length > 1) {
      return finalArray.sort((a, b) => {
        return a.period - b.period;
      });
    }
    return [];
  }

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    let qte = Math.ceil(data?.length / transformed?.length);
    setQte(qte);
    if (qte > 40) setChartType(3);
    setUpdate(true);
  }, [transformed]);

  useEffect(() => {
    if (update == true)
      setUpdate(false);
  }, [update]);

  return !update && (
    <div style={fullPage ? { margin: "1%" } : {}}>
      <DescriptionModal
        open={openModal}
        onHandle={handleDescriptionModal}
        title={`Indicador ${indicator["indicator_code"]}: ${indicator["indicator_name"]}`}
        description={indicator["indicator_description"]}
      />
      <FilterModal
        open={openFilter}
        onHandle={() => {
          userContext.toggleAccessibility();
          handleFilterModal();
        }}
        title={`Filtros para o indicador: ${indicator["indicator_name"]}`}
        hasFooter={false}
        description={
          <BuildFilters
            filters={filters}
            setFilters={setFilters}
            handleChangeKeys={handleChangeKeys}
            handleChangeMesoregionsChecked={handleChangeMesoregions}
            contrast={userContext.contrast}
            filtersState={filters}
            setAcLoading={setAcLoading}
            isLoading={acLoading}
            onHandle={handleFilterModal}
            onSave={buildURL}
            enterFlag={enterFlag}
            setEnterFlag={setEnterFlag}
            newRegions={regions}
            newMesoregions={mesoregions}
            newUf={uf}
            newCities={cities}
            setUrlUf={setUrlEstado}
            setUrlMesoregion={setUrlMesoregion}
            setUrlCities={setUrlCities}
            indicator={indicator["indicator_route"]}
          />
        }
      />
      <div className={`card_container_${userContext.contrast}`}>
        <div className="card__header">
          <div style={{ display: "flex", flexDirection: "row" }}>
            {fullPage && (
              <Button
                pstate=""
                circle="circle"
                onclick={() => redirectTo("/dashboard")}
                psize="small"
                icon="fas fa-arrow-left"
                inverted={userContext.contrast === "contrast" && "inverted"}
                tooltip={"Voltar"}
              />
            )}
          </div>
          <div className="card__header__title">
            <div className={`header_name_${userContext.contrast}`}>Indicador {indicator["indicator_code"]}</div>
            <div className={`indicator_n_${userContext.contrast}`}>{indicator["indicator_name"]}</div>
          </div>

          <div
            style={{ flex: 0.4, flexDirection: "row" }}
            className="card__header__description"
          >
            <Button
              pstate=""
              circle="circle"
              onclick={() =>
                !fullPage
                  ? redirectTo("/FullDashboardPersonalized")
                  : redirectTo("/personalized_dashboard")
              }
              psize="small"
              icon={!fullPage ? "fas fa-expand" : "fas fa-compress"}
              inverted={userContext.contrast === "contrast" && "inverted"}
              tooltip={fullPage ? "Minimizar" : "Maximizar"}
            />

            <Button
              pstate=""
              circle="circle"
              onclick={() => {
                handleFilterModal();
                userContext.closeAccessibility();
              }}
              psize="small"
              tooltip="Filtrar"
              icon="fas fa-filter"
              inverted={userContext.contrast === "contrast" && "inverted"}
            />
            <div className="dropdown" >
              <button onClick={myFunction} className={`dropbtn_${userContext.contrast} fas fa-ellipsis-v`}></button>
              <div id={"myDropdown" + id} className={`dropdown_content_${userContext.contrast}`}>
                <div className="dropdown_card" >
                  <div className={`dropdown_options_${userContext.contrast}`} >
                    <a style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} href onClick={handleDescriptionModal} >
                      <i style={{ marginRight: "10px" }} className="fas fa-info"></i>
                      Informação
                    </a>
                  </div>

                  <div className={`dropdown_options_${userContext.contrast}`} >
                    <a style={{ display: "flex", flexDirection: "row" }} onClick={() => { setDownload(!download) }} >
                      <i style={{ marginRight: "10px" }} className="fas fa-cloud-download-alt"></i>
                      Download
                    </a>
                  </div>

                  <div className={`dropdown_options_${userContext.contrast}`}  >
                    <a style={{ display: "flex", flexDirection: "row" }} href onClick={clearFilters} >
                      <i style={{ marginRight: "2px" }} className="fas fa-trash-alt"></i>
                      Limpar filtros
                    </a>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="card__filter">
          {filterSpan}
          <div className="card__filter__invisible" />
          <div className="card__filter__button">
            {indicator["indicator_presentation_shape"] !== "reports" && (

              <div className="selectDropdown" >
                <div>
                  <button
                    rollen="button"
                    className={`selectDropbtnPers_`}
                    onClick={myFunctionSelect}

                  >
                    <i style={{ marginLeft: "6px", marginTop: "5px" }}
                      className="fas fa-sort-down"></i>
                    {translateGraphicType(chartType)}
                  </button>
                </div>

                <div
                  id={"myDropdownSelect" + id}
                  className={`selectDropdown_content_`}
                >
                  <div className="selectDropdown_card">
                    <div className={`selectDropdown_options_`}>
                      <a
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        onClick={() => { setChartType(1) }}
                      >
                        Linha
                      </a>
                    </div>
                    {stackedBar && (
                      <div className={`selectDropdown_options_`}>
                        <a
                          style={{ display: "flex", flexDirection: "row" }}
                          onClick={() => { setChartType(4) }}
                        >
                          Coluna Agrupada
                        </a>
                      </div>
                    )}

                    <div className={`selectDropdown_options_`}>
                      <a
                        style={{ display: "flex", flexDirection: "row" }}
                        onClick={() => { setChartType(2) }}
                      >
                        Coluna
                      </a>
                    </div>

                    <div className={`selectDropdown_options_`}>
                      <a
                        style={{ display: "flex", flexDirection: "row" }}
                        onClick={() => { setChartType(3) }}
                      >
                        Tabela
                      </a>
                    </div>

                    <div className={`selectDropdown_options_`}>
                      <a
                        style={{ display: "flex", flexDirection: "row" }}
                        onClick={() => { setChartType(5) }}
                      >
                        Relatório
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      {/* <hr /> */}
      <div style={{ height: "20px" }}></div>
      <div
        style={
          fullPage
            ? id === 2 || id === 5 || id === 8
              ? { minHeight: "10vh" }
              : { minHeight: "50vh" }
            : {}
        }
        className="card__content"
      >
        {error ? (
          "Erro ao carregar dados"
        ) : loading || isBeingResized ? (
          <CircularProgress />
        ) : (
          <PlotGraph
            label={indicator["label"]}
            id={indicator["indicator_code"]}
            chartType={chartType}
            isStackedBar={stackedBar}
            data={data}
            presentationShape={indicator["indicator_presentation_shape"]}
            contrast={userContext.contrast}
            fullPage={fullPage}
            transformed={transformed}
            definePage={setPage}
            page={page}
            qte={qte}
          />
        )}
      </div>
      <hr />
      <div className="card__footer">
        <div>
          <i>Fonte: {indicator["indicator_source"]}</i>
        </div>
        <div>
          <i style={{ fontStyle: "italic" }}>Última atualização:{" "}</i>
          {convertDate(new Date(indicator["last_update"]).toDateString())}
        </div>
      </div>
    </div>
    </div >
  );
}

export default memo(DashboardCard);
