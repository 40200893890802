import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Perfil from "../Pages/Perfil";
import Home from "../Pages/Home";
import Management from "../Pages/Management";
import Dashboard from "../Pages/Dashboard";
import DashboardPersonalization from "../Pages/DashboardPersonalizatoin";
import DashboardSelection from "../Pages/DashboardSelection";
import Recover from "../Pages/Recover";
import PersonalizedDashboard from "../Pages/PersonalizedDashboard";
import FullPageIndicator from "../Pages/FullPageIndicator";
import FullDashboardPersonalized from "../Pages/FullDashboardPersonalized";
import PrivateRoute from "./privateRoute";
import Error404Page from "../Pages/Error404Page";
import ErrorPage from "../Pages/Error";
import Logo from "../Pages/Logo";

function routes() {
  let routes = [
    "/",
    "/recupera",
    "/perfil",
    "/management",
    "/dashboard_selection",
    "/dashboard",
    "/FullPageIndicator",
    "/personalized_dashboard",
    "/FullDashboardPersonalized",
    "/dashboard_personalization",
    "/Logo",
    "/error",
  ];
  let pnldtools = [
    "api",
    "system",
    "system2",
    "metabase",
    "cloudbeaver",
    "codeserver",
    "data",
    "datap",
    "datamart",
    "dump",
  ];
  let curentRoute = window.location.pathname;
  let validation = <></>;

  if (
    !routes.includes(curentRoute) &&
    !pnldtools.includes(curentRoute.split("/")[1])
  ) {
    validation = <Route path="*" component={() => <Redirect to="/404" />} />;
  }

  return (
    <Router>
      <Route path="/" exact component={Home} />
      <Route path="/recupera" exact component={Recover} />
      <PrivateRoute
        validation={localStorage.getItem("user") === null ? false : true}
        path="/perfil"
        exact
        component={Perfil}
      />
      <PrivateRoute
        validation={localStorage.getItem("user") === null ? false : true}
        path="/management"
        exact
        component={Management}
      />
      <Route path="/dashboard_selection" exact component={DashboardSelection} />
      <PrivateRoute
        validation={
          localStorage.getItem("publicDashboard") === null ? false : true
        }
        path="/dashboard"
        exact
        component={Dashboard}
      />
      <Route path="/FullPageIndicator" exact component={FullPageIndicator} />

      <PrivateRoute
        validation={localStorage.getItem("user") === null ? false : true}
        path="/personalized_dashboard"
        exact
        component={PersonalizedDashboard}
      />
      <PrivateRoute
        validation={localStorage.getItem("user") === null ? false : true}
        path="/FullDashboardPersonalized"
        exact
        component={FullDashboardPersonalized}
      />
      <PrivateRoute
        validation={localStorage.getItem("user") === null ? false : true}
        path="/dashboard_personalization"
        exact
        component={DashboardPersonalization}
      />
      <Route path="/Logo" exact component={Logo} />
      <Route path="/404" exact component={Error404Page} />
      <Route path="/error" exact component={ErrorPage} />
      

      {validation}
    </Router>
  );
}

export default routes;
