/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useContext } from "react";
import Backdrop from "../backdrop/backdrop";
import Modal from "./modal";
import ModalComponent from "../../ds-gov/Modal";
import "./login-modal.css";
import Button from "../../ds-gov/Button";
import UserContext from "../../../Store/user-context";
import NotificationContext from "../../../Store/notification-store";
/* Parameters
 * open: boolean
 * onHandle: function
 * title: String,
 * description: String*/

function DescriptionCardModal(props) {
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);

  let open = props.open || false;
  let onHandle = props.onHandle || function () { };
  let propsTitle = props.title || "";
  let description = props.description || "";
  let onSave = props.onSave || function () { };
  let saveLabel = props.label || "Filtrar";
  let saveIcon = props.icon || "fas fa-filter";
  let cancelLabel = props.cancelLabel || "Cancelar";
  let isLoading = props.isLoading || false;
  let hasFooter = props.hasFooter !== undefined ? props.hasFooter : true;

  const onCancelModal = () => {
    onHandle();
  };

  const onSaveModal = () => {
    if (!isLoading) {
      onSave();
      onHandle();
    } else {
      notificationContext.showNotification({
        title: "Aviso",
        message: "Os dados ainda não foram carregados, por favor aguarde",
        status: "warning",
        position: "top-right",
      });
    }
  };
  
  let title = (
    <span className={`${userContext.contrast}Title`}>{propsTitle}</span>
  );

  let cancelButton = (
    <Button
      bground="primary"
      pstate=""
      psize="small"
      label={cancelLabel}
      inverted={userContext.contrast === "inverted" && "contrast"}
      onclick={onCancelModal}
    />
  );

  let okButton = (
    <Button
      bground="secondary"
      pstate={isLoading ? "disabled" : ""}
      onclick={onSaveModal}
      psize="small"
      icon={saveIcon}
      inverted={userContext.contrast === "inverted" && "contrast"}
      label={saveLabel}
    />
  );

  let footer = hasFooter === true ? (
    <>
      {cancelButton}
      {okButton}
    </>
  ) : (
    <></>
  );
  let body = (
    <span className={`${userContext.contrast}Description`}>{description}</span>
  );

  return (
    <>
      {open && <Backdrop onClick={onCancelModal} />}
      {open && (
        <Modal>
          <ModalComponent
            title={title}
            justifyFooter="center"
            body={body}
            footer={footer}
            onCancelModal={onCancelModal}
          />
        </Modal>
      )}
    </>
  );
}

export default DescriptionCardModal;
