/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState } from "react";
import Header from "../../ds-gov/Header";
import SignIn from "../../ds-gov/SignIn";
import Menu from "../../ds-gov/Menu";
import logo from "../../../images/toast/Pnld_Claro.svg";
import logo_escuro from "../../../images/toast/Pnld_Escuro.svg";
import DescriptionCardModal from "../modals/description-modal";
/* Parameters
 * handleLogin: function
 * handleContrast: function
 * contrast: String */

export default function header(props) {
  '';
  let handleLogin = props.handleLogin || function () { };
  let handleContrast = props.handleContrast || function () { };
  let handleAccessibility = props.handleAccessibility || function () { };
  let contrast = props.contrast || "";
  let confirmLogin = props.confirmLogin || function () { };

  let role_id = "";
  if (JSON.parse(localStorage.getItem("user")))
    role_id = JSON.parse(localStorage.getItem("user"))["role_id"];

  let headerSignIn = (
    <SignIn
      type={1}
      text={"Entrar"}
      density={"small"}
      handleLogin={handleLogin}
      confirmLogin={confirmLogin}
    />
  );

  let logos = [];

  function fnBrowserDetect() {

    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      localStorage.getItem("countFont");
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    return browserName;
  }

  // Function to increase the font size
  function increaseFontSize() {
    let browser = fnBrowserDetect();
    if (browser === "firefox") {
      if (localStorage.getItem("countFont") < 8) {
        let classElements = document.getElementsByTagName("*");
        for (let i = 0; i < classElements.length; i++) {
          let elem = classElements[i];
          let styles = getComputedStyle(elem);
          elem.style.fontSize =
            parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) *
            1.05 +
            "px";
        }
        localStorage.setItem(
          "countFont",
          Number(localStorage.getItem("countFont")) + 1
        );
      }
    }
    else {
      const currentZoom = Number(localStorage.getItem("zoom")) > 0 ? Number(localStorage.getItem("zoom")) : 1;
      let zoomFactor = currentZoom + 0.1;
      const zoomLimit = 1.5;
      if (zoomFactor <= zoomLimit) document.body.style.zoom = zoomFactor;
      localStorage.setItem("zoom", document.body.style.zoom);
    }

  }

  // Function to decrease the font size
  function decreaseFontSize() {
    let browser = fnBrowserDetect();
    if (browser === "firefox") {
      if (localStorage.getItem("countFont") >= 1) {
        let classElements = document.getElementsByTagName("*");
        for (let i = 0; i < classElements.length; i++) {
          let elem = classElements[i];
          let styles = getComputedStyle(elem);
          elem.style.fontSize =
            parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) /
            1.05 +
            "px";
        }
        localStorage.setItem(
          "countFont",
          Number(localStorage.getItem("countFont")) - 1
        );
      } else if (localStorage.getItem("countFont") === 1) resetFontSize();
    }
    else {
      const currentZoom = Number(localStorage.getItem("zoom"));
      const zoomLimit = 0.5;
      if (currentZoom > zoomLimit) {
        document.body.style.zoom = currentZoom - 0.1;
      }
      localStorage.setItem("zoom", document.body.style.zoom);
    }
  }

  // Function to reset the font size to default
  function resetFontSize() {
    let browser = fnBrowserDetect();
    if (browser === "firefox") {
      let classElements = document.getElementsByTagName("*");
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        elem.style.removeProperty("font-size");
      }
      localStorage.setItem("countFont", 0);
    }
    else {
      document.body.style.zoom = 1;
      localStorage.setItem("zoom", document.body.style.zoom);
    }
  }

  let functionalities = [
    {
      buttonClass: "fas fa-adjust",
      link: <div style={{ marginLeft: "4px" }}>Modo escuro</div>,
      onClick: handleContrast,
    },
    {
      buttonClass: "fas fa-universal-access fa-lg ",
      link: "Acessibilidade",
      onClick: handleAccessibility,
    },
    {
      onClick: increaseFontSize,
      text: "A+",
      link: <div style={{ marginLeft: "8px" }}>Aumentar fonte</div>,
    },
    {
      onClick: decreaseFontSize,
      text: "A-",
      link: <div style={{ marginLeft: "8px" }}>Diminuir fonte</div>,
    },
    {
      link: "",
      onClick: resetFontSize,
      text: "A",
      link: <div style={{ marginLeft: "16px" }}>Restaurar fonte</div>,
    },
  ];

  let links = [];

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/dashboard_selection"
  ) {
    links = [{ nome: "Dashboard", link: "/dashboard_selection" }];
  }

  if (role_id === 5 || role_id === 4) {
    links.unshift({ nome: "Administração", link: "/management" });
    links.push({ nome: "Perfil", link: "/perfil" });
  } else if (role_id !== "") {
    links.push({ nome: "Perfil", link: "/perfil" });
  }

  let social = [
    {
      link: "https://www.youtube.com/FNDEMEC",
      aria_label: "log youtube",
      icon: <i className="fab fa-youtube-square fa-2x"></i>,
    },
    {
      link: "https://www.flickr.com/photos/fndemec1",
      aria_label: "log flickr",
      icon: <i className="fab fa-flickr fa-2x"></i>,
    },
    {
      link: "https://www.facebook.com/fnde.educacao",
      aria_label: "log facebook",
      icon: <i className="fab fa-facebook-square fa-2x"></i>,
    },
    {
      link: "https://twitter.com/fndeoficial",
      aria_label: "log twitter",
      icon: <i className="fab fa-twitter-square fa-2x "></i>,
    },
  ];

  let menu = [
    {
      icon: (
        <i
          className="fas fa-chart-line"
          style={{ fontSize: "20px", marginRight: "40px" }}
        ></i>
      ),
      group_link: "/dashboard_selection",
      group_name: "Dashboard",
      content: [],
    },
  ];

  if (role_id === 5 || role_id === 4) {
    menu = [
      {
        icon: (
          <i
            className="fas fa-user"
            style={{ fontSize: "20px", marginRight: "40px" }}
          ></i>
        ),
        group_link: "/perfil",
        group_name: "Perfil",
        content: [],
      },
      {
        icon: (
          <i
            className="fas fa-chart-line"
            style={{ fontSize: "20px", marginRight: "40px" }}
          ></i>
        ),
        group_link: "/dashboard_selection",
        group_name: "Dashboard",
        content: [],
      },
      {
        icon: (
          <i
            className="fas fa-wrench"
            style={{ fontSize: "20px", marginRight: "40px" }}
          ></i>
        ),
        group_link: "/management",
        group_name: "Administração",
        content: [],
      },
    ];
  } else if (role_id !== "") {
    menu = [
      {
        icon: (
          <i
            className="fas fa-user"
            style={{ fontSize: "20px", marginRight: "30px" }}
          ></i>
        ),
        group_link: "/perfil",
        group_name: "Perfil",
        content: [],
      },
      {
        icon: (
          <i
            className="fas fa-chart-line"
            style={{ fontSize: "20px", marginRight: "30px" }}
          ></i>
        ),
        group_link: "/dashboard_selection",
        group_name: "Dashboard",
        content: [],
      },
    ];
  }

  var user = role_id !== "" ? 1 : 0;
  var display_logo = contrast === "contrast" ? logo_escuro : logo;

  let headerMenu = (
    <Menu
      menu_title="Painel PNLD"
      logos={logos}
      social={social}
      items={menu}
      menu_user={user}
      contrast={contrast}
    />
  );

  return (
    <>
      <DescriptionCardModal />
      <Header
        signInButton={headerSignIn}
        logo={display_logo}
        menu={headerMenu}
        links={links}
        funcionalidades={functionalities}
        contrast={contrast}
      />
    </>
  );
}
