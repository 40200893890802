/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import api_configuration from "../api_configuration";

/* get all users data to use in table */
export function getUserTableData(setData, setLoading) {
  setLoading(true);
  fetch(
    api_configuration.api_route.get_users_api +
      api_configuration.api_route.rolesFilter,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")).token,
      },
    }
  )
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const responseData = isJson && (await response.json());
      if (response.ok) {
        treatTableContent(responseData, setData, setLoading);
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      setLoading(false);
    });
}

/* treat data to use in table */
function treatTableContent(tableContent, setData, setLoading) {
  let tableData = [];
  let userObj = {};

  for (let i = 0; i < tableContent.length; i++) {
    const user = tableContent[i];
    userObj = {
      id: user.id,
      nome: user.name,
      email: user.email,
      CPF: user.cpf,
      permission: user.role_id,
      role_name: user.roles[0].role_name,
      notesArray: "userNotes" in user ? user.userNotes : [],
      notes: "userNotes" in user ? user.userNotes[0].note : "Nenhuma Anotação",
    };
    tableData[i] = userObj;
  }
  setData(tableData);
  setLoading(false);
}

/**
 * Update data in the database
 * @param {string} route - The patch api route
 * @param {obj} body - The api body
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 * @param {function} [setLoading=function () {}] - The function that set loading state
 */
export function Patch(
  route,
  body,
  onResponseOk = function () {},
  setLoading = function () {}
) {
  setLoading(true);
  fetch(route, {
    method: "PATCH",
    headers: {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("token")).token,
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      if (response.ok) {
        onResponseOk();
        setLoading(false);
      } else {
        const error = response.statusText;
        setLoading(false);
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      setLoading(false);
    });
}

/**
 * Create data in the database
 * @param {string} route - The post api route
 * @param {obj} body - The api body
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 * @param {function} [setLoading=function () {}] - The function that set loading state
 *
 */
export function Post(
  route,
  body,
  onResponseOk = function () {},
  setLoading = function () {}
) {
  setLoading(true);
  fetch(route, {
    method: "POST",
    headers: {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("token")).token,
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      if (response.ok) {
        onResponseOk(response);
        setLoading(false);
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      setLoading(false);
    });
}

/**
 * Create data in the database without authorization token
 * @param {string} route - The post api route
 * @param {obj} body - The api body
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 * @param {function} [onResponseError=function(){}] - The function that triggers when the request fails
 */
export function PostUnauth(
  route,
  body,
  onResponseOk = function () {},
  onResponseError = function () {},
  setData = function () {}
) {
  fetch(route, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const responseData = isJson && (await response.json());
      if (response.ok) {
        onResponseOk();
        setData(responseData);
      } else {
        return response.json().then((response) => {
          throw new Error(response.error.message);
        });
      }
    })
    .catch((error) => {
      onResponseError(error.message);
    });
}

/**
 * Delete data in the database
 * @param {string} route - The delete api route
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 * @param {function} [setLoading=function () {}] - The function that set loading state
 *
 */
export function Delete(
  route,
  body = {},
  onResponseOk = function () {},
  setLoading = function () {}
) {
  setLoading(true);
  fetch(route, {
    method: "DELETE",
    headers: {
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("token")).token,
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      if (response.ok) {
        onResponseOk();
        setLoading(false);
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      setLoading(false);
    });
}

/**
 * Get data
 * @param {string} route - The get api Route
 * @param {function}[setData=function () {}] - The React Hooks Function to set data state
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 * @param {function} [setLoading=function () {}] - The function that set loading state
 *
 */
export function Get(
  route,
  setData,
  onResponseOk = function () {},
  setLoading = function () {}
) {
  setLoading(true);
  fetch(route, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("token")).token,
    },
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const responseData = isJson && (await response.json());
      if (response.ok) {
        setData(responseData);
        setLoading(false);
        onResponseOk();
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      setLoading(false);
    });
}

export function GetUnauth(route, setData) {
  fetch(route, {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const responseData = isJson && (await response.json());
      if (response.ok) {
        setData(responseData);
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {});
}

/**
 * Get data
 * @param {string} route - The get api Route
 * @param {function}[setData=function () {}] - The React Hooks Function to set data state
 */
export function GetRegions(route) {
  fetch(route, {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const responseData = isJson && (await response.json());
      if (response.ok) {
        localStorage.setItem("regions", JSON.stringify(responseData.result));
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {});
}

/**
 * Create data in the database without authorization token
 * @param {string} route - The post api route
 * @param {obj} body - The api body
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 */
export function Put(route, body, onResponseOk) {
  fetch(route, {
    method: "PUT",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(async (response) => {
      if (response.ok) {
        onResponseOk();
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {});
}

/**
 * Function to handle user login
 * @param {String} route - The API route
 * @param {Object} body - The body request object
 * @param {Boolean} rememberMe - The remember me boolean value
 * @param {Function} setRequestUser - The function to set requestUser value
 * @param {Boolean} requestUser - The requestUser value
 * @param {function} [handleError=function () {}] - The error treatment function
 */
export function login(
  route,
  body,
  rememberMe,
  setRequestUser,
  requestUser,
  handleError
) {
  fetch(route, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  })
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const responseData = isJson && (await response.json());
      if (response.ok) {
        let dt = new Date();
        dt.setHours(dt.getHours() + 6);
        let tokenObj = {
          token: responseData["accessToken"],
          refreshToken: responseData["refreshToken"],
          expiry: dt,
        };
        localStorage.setItem("token", JSON.stringify(tokenObj));
        localStorage.setItem("rememberMe", rememberMe);
        setRequestUser(!requestUser);
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      handleError();
    });
}

/**
 * request user data with two fetch's function call: 1st is the /whoAmI fetch and the 2nd is the /user fetch
 * @param {String} route - the API route
 * @param {function} [onResponseOk=function () {}]  - the function that triggers when the userRequest is ok AND the getUser request is Ok
 */
export function userRequest(route, onResponseOk) {
  fetch(route, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("token"))["token"]
      }`,
    },
  })
    .then(async (response) => {
      const responseData = await response.json();
      sessionStorage.setItem("id", responseData);
      if (response.ok) {
        getUser(
          api_configuration.api_route.user_api +
            responseData +
            api_configuration.api_route.user_api_filter,
          onResponseOk
        );
      } else {
        const error = response.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {});
}

/**
 * Get user data and saves it in localStorage
 * @param {string} route - The Api route
 * @param {function} [onResponseOk=function () {}] - The function that triggers when response is ok
 *
 */
export function getUser(route, onResponseOk) {
  if (onResponseOk === undefined) onResponseOk = function () {};
  fetch(route, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("token"))["token"]
      }`,
    },
  })
    .then(async (userResponse) => {
      const userResponseData = await userResponse.json();
      if (userResponse.ok) {
        localStorage.setItem("user", JSON.stringify(userResponseData));
        onResponseOk();
      } else {
        const error = userResponse.statusText;
        return Promise.reject(error);
      }
    })
    .catch((error) => {});
}

export {};
