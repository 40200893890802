/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import BRSelect from "./js-dsgov/Select";
import React, { useEffect } from "react";
import "./Styles/Select.css";
import "./Styles/input.css";

function Select(props) {
  let contrast = props.contrast ? props.contrast : "";
  let propsIndex = props.index ? props.index : "";
  let options = props.options ? props.options : ["Padrão"];
  let handleChange = props.handleChange ? props.handleChange : () => {};
  let placeholder = props.placeholder ? props.placeholder : "placeholder";
  let message = props.message ? props.message : "";
  let multiple = props.multiple ? props.multiple : false;
  let label = props.label ? props.label : "label padrão";
  let typeId = props.typeId ? props.typeId : "";

  let selectList = [];

  useEffect(() => {
    for (const brSelect of window.document.querySelectorAll(".br-select")) {
      selectList.push(new BRSelect("br-select", brSelect));
    }
    // eslint-disable-next-line
  }, [options]);

  if (multiple) {
    return (
      <>
        <div className="br-select" data-multiple>
          <div className={`br-input input-${contrast}`}>
            <label htmlFor={typeId}>{label}</label>
            <input id={typeId} type="text" placeholder={placeholder} />
          </div>
          <div className="br-list multipleSelect" tabIndex="0">
            <div className="br-item highlighted" data-all tabIndex="-1">
              <div className="br-checkbox">
                <input id={`cb${propsIndex}+${typeId}`} type="checkbox" />
                <label htmlFor={`cb${propsIndex}+t${typeId}`}>Selecionar todos</label>
              </div>
            </div>
            {options &&
              React.Children.toArray(
                options.map((option, index) => {
                  return (
                    <div
                      className="br-item divider"
                      tabIndex="-1"
                      key={`${option.name}_${index}`}
                    >
                      <div className="br-checkbox">
                        <input
                          id={`cb${propsIndex}${index}`}
                          type="checkbox"
                          name={option.name}
                          checked={option.checked}
                          onChange={() => {
                            handleChange(index);
                          }}
                        />
                        <label htmlFor={`cb${propsIndex}${index}`}>
                          {option.name}
                        </label>
                      </div>
                    </div>
                  );
                })
              )}
          </div>
        </div>
        {message && <div className="feedback">{message}</div>}
      </>
    );
  }

  return (
    <>
      <div className="br-select">
        <div className={`br-input input-${contrast}`}>
          <label htmlFor={typeId}>{label}</label>
          <input id={typeId} type="text" placeholder={placeholder} />
          <button
            className="br-button circle small"
            type="button"
            tabIndex="-1"
            data-trigger
          >
            <span className="sr-only">Exibir lista</span>
            <i className="fas fa-angle-down"></i>
          </button>
        </div>
        <div className="br-list" tabIndex="0">
          {options.map((option, index) => {
            return (
              <div
                className="br-item divider"
                tabIndex="-1"
                key={`${option.name}_${index}`}
              >
                <div className="br-checkbox">
                  <input
                    id={`cb${index}+${typeId}`}
                    type="checkbox"
                    name={option.name}
                    checked={option.checked}
                    onChange={() => {
                      handleChange(index);
                    }}
                  />
                  <label checked={option.checked} htmlFor={`cb${index}+${typeId}`}>
                    {option.name}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        {message && <div className="feedback">{message}</div>}
      </div>
    </>
  );
}

export default Select;
