/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext, useEffect } from "react";
import "./profile.css";
import Input from "../../ds-gov/Input";
import Button from "../../ds-gov/Button";
import Card from "../../ds-gov/Card";
import api_configuration from "../../../api_configuration";
import UserContext from "../../../Store/user-context";
import NotificationContext from "../../../Store/notification-store";
import {
  handleShowPassword,
  handleFieldChange,
  isFieldValid,
  isFieldInvalid,
  validatePassword,
  validateConfirmPassword,
  handleEmailValidation,
} from "../modals/utils/validation";
import { Patch } from "../../../Store/apiFunctions";

function ProfileComponent() {
  const userContext = useContext(UserContext);
  const notificationContext = useContext(NotificationContext);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isEdittingEmail, setEdittingEmail] = useState(false);
  const [isEdittingPassword, setEdittingPassword] = useState(false);
  const [isEdittingConfirmPassword, setEdittingConfirmPassword] =
    useState(false);
  const [newWorkPlace, setNewWorkPlace] = useState(user.job_place || "");
  const [newOcuppation, setNewOcuppation] = useState(user.job_position || "");
  const [isEmailValid, setIsEmailValid] = useState(undefined);
  const [newEmail, setNewEmail] = useState(user.email);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(undefined);
  const [finishPatch, setFinishPatch] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
    useState(undefined);
  const [newFullName, setNewFullName] = useState(user.name || "");

  useEffect(() => {
    let tam = localStorage.getItem('countFont');
    let classElements = document.getElementsByTagName("*");
    for (let j = 0; j < tam - 1; j++) {
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        let styles = getComputedStyle(elem);
        elem.style.fontSize = (parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) * 1.05 + 'px');
      }
      j += 1;
    }
    const currentZoom = Number(localStorage.getItem("zoom"));
    document.body.style.zoom = currentZoom;
    userContext.setBreadLocation([
      { name: "Perfil", href: "/perfil", clickable: false },
    ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (finishPatch) {
      userContext.getUser(user.id);
      setFinishPatch(false);
    }
    // eslint-disable-next-line
  }, [finishPatch]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    // eslint-disable-next-line
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line
  }, [newEmail, newPassword, confirmPassword, newWorkPlace]);

  useEffect(() => {
    if (newEmail !== user.email)
      handleEmailValidation(setIsEmailValid, newEmail);
    // eslint-disable-next-line
  }, [newEmail]);

  useEffect(() => {
    if (newPassword.length > 0) {
      validatePassword(setIsPasswordValid, newPassword);
      validateConfirmPassword(
        setIsConfirmPasswordValid,
        confirmPassword,
        newPassword
      );
    } else {
      setIsPasswordValid(undefined);
      setEdittingPassword(false);
    }
    // eslint-disable-next-line
  }, [newPassword]);

  useEffect(() => {
    if (confirmPassword.length > 0) {
      validateConfirmPassword(
        setIsConfirmPasswordValid,
        confirmPassword,
        newPassword
      );
    } else {
      setIsConfirmPasswordValid(undefined);
      setEdittingConfirmPassword(false);
    }
    // eslint-disable-next-line
  }, [confirmPassword]);

  const handleShowNotification = (title, message, status) => {
    notificationContext.showNotification({
      title: title,
      message: message,
      status: status,
      position: "top-right",
    });
  };

  const updateUser = () => {
    handleShowNotification("Sucesso!", "Dados atualizados", "success");
    setEdittingEmail(false);
    setEdittingPassword(false);
    setEdittingConfirmPassword(false);
    setShowPassword(false);
    setIsConfirmPasswordValid(undefined);
    setIsEmailValid(undefined);
    setIsPasswordValid(undefined);
  };

  /**
   * Transform a single digit number into a double digit number.
   * @param {string} bdate - number between 1-31.
   */

  function bdayFormatted(bdate) {
    const bdate_int = parseInt(bdate);
    return bdate_int <= 9 ? "0" + bdate : bdate;
  }

  const getBirthDate = () => {
    let bday = new Date(user.birth_date);
    bday = new Date(bday.toUTCString());
    let bdate =
      bdayFormatted(bday.getUTCDate().toString()) +
      "/" +
      bdayFormatted((bday.getUTCMonth() + 1).toString()) +
      "/" +
      bday.getUTCFullYear();
    return bdate;
  };

  const handleSaveUser = () => {
    let userObj = {
      email: newEmail,
      job_place: newWorkPlace,
      job_position: newOcuppation,
      name: newFullName,
      /* need to change route to update password */
    };
    if (newPassword !== "") userObj.password = newPassword;
    Patch(api_configuration.api_route.user_self_api + user.id, userObj, () => {
      setFinishPatch(true);
    });
    updateUser();
  };

  const validateInputs = () => {
    /* if user hasn't changed any data, there is nothing to save */
    if (
      isPasswordValid === undefined &&
      isConfirmPasswordValid === undefined &&
      isEmailValid === undefined &&
      (newWorkPlace === user.job_place ||
        (newWorkPlace === "" && user.job_place === null)) &&
      (newOcuppation === user.job_position ||
        (newOcuppation === "" && user.job_position === null)) &&
      (newFullName === user.name || (newFullName === "" && user.name === null))
    )
      return false;
    /* else if user input's isn't valid, then you should not be able to save */ else if (
      (isPasswordValid !== undefined && isConfirmPasswordValid === undefined) ||
      (isPasswordValid === undefined && isConfirmPasswordValid !== undefined) ||
      isPasswordValid === false ||
      isConfirmPasswordValid === false ||
      isEmailValid === false
    )
      return false;
    /* else, user's input is valid and you should be able to save */ else
      return true;
  };

  return (
    <div className={`profilecard${userContext.contrast}`}>
      <div>
        <Card
          cardContrast={userContext.contrast}
          cardType="complete"
          content={
            <div className={`shadow-box${userContext.contrast}`}>
              <div className={`user-profile${userContext.contrast}`}>
                {user.name}
              </div>
              <div className={`user-infor${userContext.contrast}`}>
                <br /> CPF: {user.cpf}
                <br /> Data de nascimento: {getBirthDate()}
                <br /> Permissão: {user.roles[0].role_name}
              </div>
              <hr></hr>

              <div className="perfil-card">
                <div className="sized-box" />
                <p className={`title-profile${userContext.contrast}`}>
                  Dados Pessoais
                </p>
                <div className="div-box">
                  <div
                    onClick={() => userContext.closeAccessibility()}
                    onFocus={() => userContext.closeAccessibility()}
                    onBlur={() => userContext.toggleAccessibility()}
                  >
                    <Input
                      brInputClassName={`br-input input-${userContext.contrast}`}
                      labelText="Nome"
                      inputType="text"
                      inputValue={newFullName}
                      inputPlaceholder={"Ex: Meu Nome"}
                      handleChange={(e) => handleFieldChange(e, setNewFullName)}
                      onBlur={() => {}}
                      labelFor="Name"
                      inputId="Name"
                    />
                  </div>

                  <div className="sized-box" />
                  <div
                    onClick={() => userContext.closeAccessibility()}
                    onFocus={() => userContext.closeAccessibility()}
                    onBlur={() => userContext.toggleAccessibility()}
                  >
                    <Input
                      brInputClassName={
                        isEdittingEmail
                          ? isEmailValid
                            ? `br-input success input-${userContext.contrast}`
                            : ` br-input danger input-${userContext.contrast} `
                          : `br-input input-${userContext.contrast}`
                      }
                      showMessage={
                        isEdittingEmail ? isEmailValid || !isEmailValid : false
                      }
                      messageText={
                        isEmailValid ? "Email válido" : "Email inválido"
                      }
                      messageIcon={
                        isEmailValid
                          ? "fas fa-check-circle"
                          : "fas fa-times-circle"
                      }
                      feedBackType={
                        isEmailValid ? "success" : !isEmailValid ? "danger" : ""
                      }
                      labelText="Email"
                      type="text"
                      inputValue={newEmail}
                      inputPlaceholder={"Ex: meunome@dominio.com"}
                      handleChange={(e) => {
                        handleFieldChange(e, setNewEmail);
                        setEdittingEmail(true);
                      }}
                      onBlur={() => {
                        userContext.toggleAccessibility();
                      }}
                      labelFor="input-email"
                      inputId="input-email"
                    />
                  </div>
                </div>

                <div className="div-box">
                  <div
                    onClick={() => userContext.closeAccessibility()}
                    onFocus={() => userContext.closeAccessibility()}
                    onBlur={() => userContext.toggleAccessibility()}
                  >
                    <Input
                      brInputClassName={`br-input input-${userContext.contrast}`}
                      labelText="Local de trabalho"
                      inputType="text"
                      inputValue={newWorkPlace}
                      inputPlaceholder={
                        "Ex: Colégio Estadual Fulano e Beltrano"
                      }
                      handleChange={(e) => {
                        handleFieldChange(e, setNewWorkPlace);
                      }}
                      onBlur={() => {}}
                      labelFor="input-edit-workplace"
                      inputId="input-edit-workplace"
                    />
                  </div>
                  <div className="sized-box" />
                  <div
                    onClick={() => userContext.closeAccessibility()}
                    onFocus={() => userContext.closeAccessibility()}
                    onBlur={() => userContext.toggleAccessibility()}
                  >
                    <Input
                      brInputClassName={`br-input input-${userContext.contrast}`}
                      labelText="Função"
                      inputType="text"
                      inputValue={newOcuppation}
                      inputPlaceholder={"Ex: Docente"}
                      handleChange={(e) => {
                        handleFieldChange(e, setNewOcuppation);
                      }}
                      onBlur={() => {}}
                      labelFor="input-edit-workplace"
                      inputId="input-edit-workplace"
                    />
                  </div>
                </div>

                <div className="div-space" />
                <p className={`title-profile${userContext.contrast}`}>
                  Alterar senha
                </p>

                <div className="div-box">
                  <div
                    onClick={() => userContext.closeAccessibility()}
                    onFocus={() => userContext.closeAccessibility()}
                    onBlur={() => userContext.toggleAccessibility()}
                  >
                    <Input
                      brInputClassName={
                        isEdittingPassword
                          ? isFieldValid(isPasswordValid)
                            ? `br-input success input-${userContext.contrast} password-input`
                            : ` br-input danger input-${userContext.contrast} password-input`
                          : `br-input input-${userContext.contrast} password-input`
                      }
                      showMessage={
                        isEdittingPassword
                          ? isFieldValid(isPasswordValid) ||
                            isFieldInvalid(isPasswordValid)
                          : false
                      }
                      messageText={
                        isFieldValid(isPasswordValid)
                          ? "A senha é válida"
                          : "A senha é inválida, precisa ter 8 caracteres ou mais"
                      }
                      messageIcon={
                        isFieldValid(isPasswordValid)
                          ? "fas fa-check-circle"
                          : "fas fa-times-circle"
                      }
                      feedBackType={
                        isFieldValid(isPasswordValid)
                          ? "success"
                          : isFieldInvalid(isPasswordValid)
                          ? "danger"
                          : ""
                      }
                      labelText="Senha"
                      inputType={showPassword ? "text" : "password"}
                      inputPlaceholder="senha"
                      handleChange={(e) => {
                        handleFieldChange(e, setNewPassword);
                        setEdittingPassword(true);
                      }}
                      onBlur={() => {}}
                      labelFor="input-new-password"
                      inputId="input-new-password"
                      hasButton={true}
                      buttonClassName="br-button circle small"
                      buttonType="button"
                      buttonIcon={
                        showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                      }
                      handleClick={() => handleShowPassword(setShowPassword)}
                    />
                  </div>

                  <div className="sized-box" />

                  <div
                    onClick={() => userContext.closeAccessibility()}
                    onFocus={() => userContext.closeAccessibility()}
                    onBlur={() => userContext.toggleAccessibility()}
                  >
                    <Input
                      brInputClassName={
                        isEdittingConfirmPassword
                          ? isFieldValid(isPasswordValid)
                            ? isFieldValid(isConfirmPasswordValid)
                              ? `br-input success input-${userContext.contrast} password-input`
                              : ` br-input danger input-${userContext.contrast} password-input`
                            : ` br-input danger input-${userContext.contrast} password-input`
                          : `br-input input-${userContext.contrast} password-input`
                      }
                      showMessage={
                        isEdittingConfirmPassword
                          ? isFieldValid(isConfirmPasswordValid) ||
                            isFieldInvalid(isConfirmPasswordValid)
                          : false
                      }
                      messageText={
                        isFieldValid(isPasswordValid)
                          ? isFieldValid(isConfirmPasswordValid)
                            ? "As senhas são iguais"
                            : "As senhas não são iguais"
                          : "A senha é inválida, precisa ter 8 caracteres ou mais"
                      }
                      messageIcon={
                        isFieldValid(isPasswordValid)
                          ? isFieldValid(isConfirmPasswordValid)
                            ? "fas fa-check-circle"
                            : "fas fa-times-circle"
                          : "fas fa-times-circle"
                      }
                      feedBackType={
                        isFieldValid(isPasswordValid)
                          ? isFieldValid(isConfirmPasswordValid)
                            ? "success"
                            : "danger"
                          : "danger"
                      }
                      labelText="Confirme sua senha"
                      inputType={showConfirmPassword ? "text" : "password"}
                      inputValue={confirmPassword}
                      inputPlaceholder="confirme sua senha"
                      handleChange={(e) => {
                        handleFieldChange(e, setConfirmPassword);
                        setEdittingConfirmPassword(true);
                      }}
                      onBlur={() => {}}
                      labelFor="input-confirm-new-password"
                      inputId="input-confirm-new-password"
                      hasButton={true}
                      buttonClassName="br-button circle small"
                      buttonType="button"
                      buttonIcon={
                        showConfirmPassword ? "fas fa-eye-slash" : "fas fa-eye"
                      }
                      handleClick={() =>
                        handleShowPassword(setShowConfirmPassword)
                      }
                    />
                  </div>
                </div>
                <div className="div-space" />

                <div className="save-btn-container">
                  <Button
                    bground={
                      userContext.contrast === "contrast"
                        ? "secondary"
                        : "primary"
                    }
                    psize="large"
                    pstate={validateInputs() ? "" : "disabled"}
                    label="Salvar alterações"
                    onclick={handleSaveUser}
                  />
                </div>
              </div>
              <div className="sized-box" />
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ProfileComponent;
