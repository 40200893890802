/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Painel-pnld.

Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/
import BRHeader from "./js-dsgov/Header"; //NOTE: alterado função _setDropdownBehavior()
import React, { useContext, useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import UserContext from "../../Store/user-context";
import "./Styles/Header.css";
function Header(props) {
  /*
    **Parameters**
      - logo: image
      - links: list of {"nome":"nome","link":"link"}
      - assinatura: string
      - funcionalidades: List of button className and link
      - searchText: content of search

      - notification: notification
      - menu: menu
      - avatar: avatar
      - signInButton: signIn

      - density: small, medium or large,
      - sticky: true or false
  */

  let logo = props.logo ? props.logo : "";
  let links = props.links ? props.links : [{ nome: "nome", link: "link" }];
  let assinatura = props.assinatura ? props.assinatura : "";
  let menu = props.menu ? props.menu : " <div><div/>";
  let funcionalidades = props.funcionalidades
    ? props.funcionalidades
    : [{ buttonclassName: "fas fa-adjust", link: "contrast" }];
  let signInButton = props.signInButton ? props.signInButton : "";
  let contrast = props.contrast || "";
  let headerList = [];
  const [, setWidth] = useState(window.innerWidth);

  const userContext = useContext(UserContext);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    for (const brHeader of window.document.querySelectorAll(".br-header")) {
      headerList.push(new BRHeader("br-header", brHeader));
    }
    // eslint-disable-next-line
  }, []);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("user")]);

  return (
    <header
      className="br-header small sticky"
      style={
        contrast === "contrast"
          ? { backgroundColor: "var(--color-primary-darken-02)", zIndex: 2 }
          : { zIndex: 2 }
      }
    >
      <div className="contlg">
        <div className="header-top">
          <div className="header-logo">
            {logo && (
              <a href="/" className="logo-logo">
                <img src={logo} alt="Logo" style={{ width: "100px" }} />
              </a>
            )}
            {logo && assinatura && (
              <span className="br-divider vertical mx-half mx-sm-1"></span>
            )}
            {assinatura && (
              <div
                className="header-sign"
                style={
                  contrast === "contrast"
                    ? { color: "var(--color-secondary-01)" }
                    : {}
                }
              >
                {assinatura}
              </div>
            )}
          </div>
          <div className="header-actions">
            {links.length > 0 ? (
              <div className="header-links dropdown">
                <button
                  style={{ display: "none" }}
                  className="br-button circle small"
                  type="button"
                  data-toggle="dropdown"
                  aria-label="Abrir Acesso Rápido"
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={
                      contrast === "contrast"
                        ? {
                          color: "var(--color-secondary-01)",
                          display: "none",
                        }
                        : { color: "var(--color-primary-01)", display: "none" }
                    }
                    aria-hidden="true"
                  ></i>
                </button>

                <div className="br-list">
                  <div
                    className="header"
                    style={
                      contrast === "contrast"
                        ? {
                          backgroundColor: "var(--color-primary-darken-02)",
                          color: "var(--color-secondary-01)",
                        }
                        : {}
                    }
                  >
                    <div className="title">Acesso Rápido</div>
                  </div>
                  {links.map((link, index) => {
                    return (
                      <a
                        key={new Date().toISOString() + index}
                        className="br-item"
                        style={
                          contrast === "contrast"
                            ? {
                              backgroundColor:
                                "var(--color-primary-darken-02)",
                              color: "var(--color-secondary-01)",
                              textDecoration: "underline",
                            }
                            : {}
                        }
                        href={link.link}
                      >
                        {link.nome}
                      </a>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}

            {links.length > 0 && dimensions.width > 1279 ? (
              <span className="br-divider vertical mx-half mx-sm-1"></span>
            ) : (
              ""
            )}

            <div className="header-functions dropdown">
              <button
                className="br-button circle small"
                type="button"
                data-toggle="dropdown"
                aria-label="Abrir Funcionalidades do Sistema"
              >
                <i
                  className="fas fa-th"
                  aria-hidden="true"
                  style={
                    contrast === "contrast"
                      ? { color: "var(--color-secondary-01)" }
                      : {}
                  }
                ></i>
              </button>
              <div className="br-list" style={{ marginRight: "10%" }}>
                <div
                  className="header"
                  style={
                    contrast === "contrast"
                      ? {
                        backgroundColor: "var(--color-primary-darken-02)",
                      }
                      : {}
                  }
                >
                  <div
                    className="title"
                    style={
                      contrast === "contrast"
                        ? {
                          color: "var(--color-secondary-01)",
                        }
                        : {}
                    }
                  >
                    Funcionalidades do Sistema
                  </div>
                </div>
                {funcionalidades.map((funcionalidade, index) => {
                  return (
                    <div
                      className="align-items-center br-item"
                      style={
                        contrast === "contrast"
                          ? {
                            backgroundColor: "var(--color-primary-darken-02)",
                          }
                          : {}
                      }
                      key={new Date().toISOString() + index}
                    >
                      <button
                        className="br-button circle small"
                        style={
                          contrast === "contrast"
                            ? { color: "var(--color-secondary-01)" }
                            : {}
                        }
                        type="button"
                        aria-label={funcionalidade.link}
                        onClick={funcionalidade.onClick}
                      >
                        <i
                          className={funcionalidade.buttonClass}
                          aria-hidden="true"
                        ></i>
                        <div
                          style={{
                            fontWeight: "500",
                            marginLeft: "0px",
                            marginRight: "0px",
                            fontSize: "17px",
                          }}
                        >
                          {funcionalidade.text}
                        </div>
                        <span className="text" style={{ fontSize: "16px" }}>
                          {funcionalidade.link}
                        </span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="header-login">
              { }
              <div
                className={
                  localStorage.getItem("token") === null ||
                    localStorage.getItem("token") === ""
                    ? "header-sign-in"
                    : ""
                }
              >
                {localStorage.getItem("token") === null ||
                  localStorage.getItem("token") === ""
                  ? signInButton
                  : ""}
              </div>
              <div
                className={
                  localStorage.getItem("token") === null ||
                    localStorage.getItem("token") === ""
                    ? "header-avatar d-none"
                    : "header-avatar"
                }
              >
                <div className="avatar dropdown">
                  <span
                    className="br-avatar"
                    title={user ? user["name"] : "nome"}
                  >
                    <span className="image profile-image">
                      <Avatar>
                        {user
                          ? user["name"]
                            .match(/(\b\S)?/g)
                            .join("")
                            .match(/(^\S|\S$)?/g)
                            .join("")
                            .toUpperCase()
                            .slice(0, 2)
                          : ""}
                      </Avatar>
                    </span>
                  </span>
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Abrir Menu de usuário"
                    data-toggle="dropdown"
                    id="dropdownMenuButton"
                  >
                    <i
                      style={contrast === "contrast" ? { color: "white" } : {}}
                      className="fas fa-angle-down"
                      aria-hidden="true"
                    ></i>
                  </button>
                  <div className="br-notification">
                    <div className="notification-header">
                      <div className="row">
                        <div className="col-10">
                          <span className="text-bold">
                            {user ? user["name"] : ""}
                          </span>
                          <br />
                          <small>{user ? user["email"] : ""}</small>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ backgroundColor: "#dcdcdc" }}
                      className="notification-body"
                    >
                      <div className="br-tab" onClick={() => userContext.onHandleLogOut()}>
                        <nav className="tab-nav">
                          <div className="logout">
                            <i className="fas fa-sign-out-alt" style={{display:"flex", flexDirection:"row"}}>
                              <p className="logout-name">
                                Sair
                              </p>
                            </i>
                          </div>
                          {/* <ul>
                            <li className="tab-item">
                              <button
                                type="button"
                                data-panel="notification-panel-1-27981"
                              >
                                <span className="name">
                                  <span className="d-flex flex-column flex-sm-row">
                                    <span
                                      style={{ color: "#071d41" }}
                                      className="icon mb-1 mb-sm-0 mr-sm-1"
                                    >
                                      <i
                                        className="fas fa-sign-out-alt"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span
                                      style={{ color: "#071d41" }}
                                      className="name_logout"
                                    >
                                      Sair
                                    </span>
                                  </span>
                                </span>
                              </button>
                            </li>
                          </ul> */}
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {menu}
      </div>
    </header>
  );
}

export default Header;
