/*
Copyright (C) 2021 Centro de Computacao Cientifica e Software Livre
Departamento de Informatica - Universidade Federal do Parana - C3SL/UFPR

This file is part of Frontend-Painel-pnld.

Frontend-Painel-pnld is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Frontend-Painel-pnld is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Frontend-Painel-pnld  If not, see <https://www.gnu.org/licenses/>.
*/

import React, { useState, useContext, useEffect } from "react";
import Backdrop from "../backdrop/backdrop";
import Modal from "./modal";
import ModalComponent from "../../ds-gov/Modal";
import Input from "../../ds-gov/Input";
import {
  handleShowPassword,
  handleFieldChange,
  handleEmailValidation,
  isFieldValid,
  isFieldInvalid,
  validatePassword,
} from "./utils/validation";
import "./login-modal.css";
import Button from "../../ds-gov/Button";
import UserContext from "../../../Store/user-context";
import NotificationContext from "../../../Store/notification-store";
/* Parameters
 * open: boolean
 * onHandle: functions
 * onOpenSignupModal: function
 * onOpenForgotPassModal: function */

function LoginModal(props) {
  const userContext = useContext(UserContext);
  const notificatinoContext = useContext(NotificationContext);
  let open = props.open || false;
  let onHandle = props.onHandle || function () {};
  let onOpenSignupModal = props.onOpenSignupModal || function () {};
  let onOpenForgotPassModal = props.onOpenForgotPassModal || function () {};

  const [email, setEmail] = useState("");
  const [isEdittingEmail, setEdittingEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [passwordField, setPasswordField] = useState("");
  const [isEdittingPassword, setEdittingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    handleEmailValidation(setIsEmailValid, email);
  }, [email]);

  useEffect(() => {
    let tam = localStorage.getItem('countFont');
    let classElements = document.getElementsByTagName("*");
    for (let j = 0; j < tam - 1; j++) {
      for (let i = 0; i < classElements.length; i++) {
        let elem = classElements[i];
        let styles = getComputedStyle(elem);
        elem.style.fontSize = (parseFloat(styles.fontSize.slice(0, styles.fontSize.length - 2)) * 1.05 + 'px');
      }
      j += 1;
    }
    const currentZoom = Number(localStorage.getItem("zoom"));
    document.body.style.zoom = currentZoom;
  }, [open]);

  useEffect(() => {
    validatePassword(setIsPasswordValid, passwordField);
  }, [passwordField]);

  const resetFields = () => {
    setEmail("");
    setIsEmailValid(false);
    setEdittingEmail(false);
    setPasswordField("");
    setEdittingPassword(false);
    setShowPassword(false);
    setIsPasswordValid(false);
    setRememberMe(false);
  };

  const handleForgotPass = () => {
    resetFields();
    onOpenForgotPassModal();
  };

  const handleOpenSignupModal = () => {
    resetFields();
    onOpenSignupModal();
  };

  const onCancelModal = () => {
    resetFields();
    onHandle();
  };

  const onHandleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const validateCredentials = () => {
    return isEmailValid && isFieldValid(isPasswordValid);
  };

  const handleSignIn = () => {
    userContext.handleLoginTry(email, passwordField, rememberMe, onHandle, () =>
      notificatinoContext.showNotification({
        title: "Erro",
        message: "Não foi possível fazer o login",
        status: "error",
        position: "top-right",
      })
    );
  };

  const [enterFlag, setEnterFlag] = useState(undefined);

  useEffect(() => {
    if (enterFlag !== undefined) {
      setEnterFlag(undefined);

      userContext.handleLoginTry(
        email,
        passwordField,
        rememberMe,
        onHandle,
        () =>
          notificatinoContext.showNotification({
            title: "Erro",
            message: "Não foi possível fazer o login",
            status: "error",
            position: "top-right",
          })
      );
    }
    // eslint-disable-next-line
  }, [enterFlag]);

  useEffect(() => {
    if (open) {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          setEnterFlag(true);
        }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
      // eslint-disable-next-line
    }
  }, [open, enterFlag]);

  useEffect(() => {
    if (open) {
      const keyDownHandler = (event) => {
        if (event.key === "Escape") {
          event.preventDefault();
          onCancelModal();
        }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }
    // eslint-disable-next-line
  }, [open, enterFlag]);

  let title = (
    <span className={`${userContext.contrast}Title`}>
      Preencha suas informações de login ou cadastre-se
    </span>
  );

  let signupButton = (
    <Button
      bground="secondary"
      pstate=""
      psize="small"
      label="Cadastrar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      icon="fas fa-user-plus"
      onclick={handleOpenSignupModal}
    />
  );

  let forgotPassButton = (
    <Button
      bground="secondary"
      pstate=""
      psize="small"
      label="Esqueceu a senha?"
      inverted={userContext.contrast === "contrast" && "inverted"}
      onclick={handleForgotPass}
    />
  );

  let signInButton = (
    <Button
      bground="primary"
      pstate={validateCredentials() ? "" : "disabled"}
      psize="small"
      label="Entrar"
      inverted={userContext.contrast === "contrast" && "inverted"}
      icon="fas fa-sign-in-alt"
      onclick={handleSignIn}
    />
  );

  let footer = (
    <>
      {signupButton}
      {signInButton}
      {forgotPassButton}
    </>
  );
  let body = (
    <>
      <Input
        brInputClassName={
          isEdittingEmail
            ? isEmailValid
              ? `br-input success input-${userContext.contrast}`
              : ` br-input danger input-${userContext.contrast} `
            : `br-input input-${userContext.contrast}`
        }
        showMessage={isEdittingEmail ? isEmailValid || !isEmailValid : false}
        messageText={isEmailValid ? "Email válido" : "Email inválido"}
        messageIcon={
          isEmailValid ? "fas fa-check-circle" : "fas fa-times-circle"
        }
        feedBackType={isEmailValid ? "success" : !isEmailValid ? "danger" : ""}
        labelText="Email"
        type="text"
        inputValue={email}
        inputPlaceholder={"Digite o seu email"}
        handleChange={(e) => {
          handleFieldChange(e, setEmail);
          setEdittingEmail(true);
        }}
        onBlur={() => handleEmailValidation(setIsEmailValid, email)}
        labelFor="input-email"
        inputId="input-email"
      />
      <div className="sized-box__form" />
      <Input
        brInputClassName={
          isEdittingPassword
            ? isFieldValid(isPasswordValid)
              ? `br-input success input-${userContext.contrast}`
              : `br-input danger input-${userContext.contrast}`
            : `br-input input-${userContext.contrast}`
        }
        showMessage={
          isEdittingPassword
            ? isFieldValid(isPasswordValid) || isFieldInvalid(isPasswordValid)
            : false
        }
        messageText={
          isFieldValid(isPasswordValid)
            ? "A senha é válida"
            : "A senha é inválida, precisa ter 8 caracteres ou mais"
        }
        messageIcon={
          isFieldValid(isPasswordValid)
            ? "fas fa-check-circle"
            : "fas fa-times-circle"
        }
        feedBackType={
          isFieldValid(isPasswordValid)
            ? "success"
            : isFieldInvalid(isPasswordValid)
            ? "danger"
            : ""
        }
        labelText="Senha"
        inputType={showPassword ? "text" : "password"}
        inputValue={passwordField}
        inputPlaceholder="Digite sua senha"
        handleChange={(e) => {
          handleFieldChange(e, setPasswordField);
          setEdittingPassword(true);
          validatePassword(setIsPasswordValid, passwordField);
        }}
        onBlur={() => validatePassword(setIsPasswordValid, passwordField)}
        labelFor="input-password"
        inputId="input-password"
        hasButton={true}
        buttonClassName="br-button circle small"
        buttonType="button"
        buttonArialLabel="Mostrar senha"
        buttonIcon={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
        handleClick={() => handleShowPassword(setShowPassword)}
      />
      <div className="sized-box__form" />
      <div className={`${userContext.contrast}Checkbox`}>
        <label>
          <input
            type="checkbox"
            id="save"
            name="save"
            value={rememberMe}
            onChange={onHandleRememberMe}
          />{" "}
          Mantenha-me conectado
        </label>
      </div>
    </>
  );

  return (
    <>
      {open && <Backdrop onClick={onCancelModal} />}
      {open && (
        <Modal>
          <ModalComponent
            title={title}
            justifyFooter="center"
            body={body}
            footer={footer}
            onCancelModal={onCancelModal}
          />
        </Modal>
      )}
    </>
  );
}

export default LoginModal;
